
.root {
}

.header {
    background-color: white;
    border: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.modalContent {
    border-radius: 15px;
}

.upgrade-modal-body {
    padding-top: 0!important;
    padding-bottom: 0!important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    font-size: 18px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.woofyImageContainer {
    width: 40%;
    position: absolute;
    bottom: 0;
    left: 0;
}

.mainContent {
    padding: 0 24px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.dogIcon {
    width: 100px;
    height: 100px;
    position: absolute;
    top: -6.3rem;
    right: 12.5rem;
}

.upgradeText {
    font-weight: bold;
}

.planName {
    text-transform: uppercase;
}

.emailLink {
    display: block;
}

.upgradeButton {
    text-transform: uppercase;
    padding: 10px 15px;
}

