/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, January 2021
 */

.calendar-content-picker-hashtag-item-container {
    width: 6.4rem;
    position: relative;
    margin: .25rem;
}

.calendar-content-picker-hashtag-item-container .hashtag-item {
    max-width: 9rem;
    height: 1.5rem;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 5px 10px 0 rgba(69, 69, 70, 0.1);
    margin: 0 0 .25rem;
    padding: 0 .25rem;
    color: #747b95;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: .7rem;
    font-weight: 600;
    text-align: justify;
    line-height: 1.5rem;
}

.calendar-content-picker-hashtag-item-container .hashtag-item:last-child {
    margin-right: 0;
}

.calendar-content-picker-hashtag-item-container .hashtag-item.current-tag {
    background-color: #705eff;
    color: white;
}

.calendar-content-picker-hashtag-item-container .hashtag-item-full-text {
    position: absolute;
    top: -2rem;
    left: 0;
    display: flex;
    align-items: center;
    text-align: justify;
    height: 1.5rem;
    border-radius: .25rem;
    background-color: #fbfbfb;
    font-size: .75rem;
    font-weight: bold;
    z-index: 999;
    box-shadow: 0 5px 10px 0 rgba(69, 69, 70, 0.1);
    width: auto;
    padding: 0 .25rem;
    color: #39d1f8;
}
