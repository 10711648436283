
/* crop sub menu top menu */
.crop-img-component .crop-img-submenu {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 8px;
}
.crop-img-submenu .separate.sep-vertical {
    border-right: 1px solid #2f3638eb;
    height: 100%;
    margin: 0 4px 0 4px;
}
.crop-img-submenu .crop-submenu-icon-btn {
    transform: scale(1);
    transition: transform .3s ease-in-out;
}
.crop-img-submenu .crop-submenu-icon-btn:hover {
    cursor: pointer;
    transform: scale(1.1);
}

/* crop template */
.crop-img-template {
    display: flex;
    flex-direction: row;
}
.crop-img-template .crop-img-component {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    flex: 4;
}
.crop-img-template .crop-img-menu {
    flex: 2;
    display: flex;
    flex-direction: column;
    padding-right: 8px;
}
.crop-img-template .crop-img-component {
}

.crop-img-component .crop-img-editor-container{
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    background-color: #efefef;
    border-radius: .3rem;
}
.crop-img-editor-container .preview-crop-img{
    max-width: 100%;
    max-height: 350px;
    object-fit: contain;
}

/* presets left menu */
.crop-preset-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 8px;
}
.crop-preset-list .crop-img-preset {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 8px;
}
.crop-img-preset .crop-preset-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    border-radius: .3rem;
    padding: 8px;
    min-height: 80px;
    border: 1px #2f3638eb solid;
    transform: scale(1);
    transition: transform .3s ease-in-out;
}
.crop-img-preset .crop-preset-item:hover {
    cursor: pointer;
    transform: scale(1.05);
}
.crop-img-preset .crop-preset-item.preset-selected {
    border: 1px #18d1f9 solid;
    transform: scale(1.02);
}
.crop-img-preset .crop-preset-item.disabled {
    border: 1px #212529 solid;
    cursor: not-allowed;
    transform: scale(1);
}
.crop-img-preset .crop-preset-item.disabled.preset-selected {
    border: 1px #18d1f9 solid;
}
.crop-preset-item .crop-preset-title {
    text-align: center;
}

.icon-crop-preview-img {
    background-color: white;
    border-radius: 50%;
    padding: 4px;
    max-width: 28px;
}