/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, November 2020
 */

#app-carousel .slide {
    background-color: #e2e2e2;
}

#app-carousel .slide.selected {
    height: min-content;
}

#app-carousel .carousel-status {
    width: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 .25rem;
    text-shadow: none;
    left: calc(50% - 3rem);
    font-size: 1.25rem;
    color: #4285f4;
}

#app-carousel .control-prev.control-arrow:before {
    border-right: 8px solid #737a95;
}
#app-carousel .control-prev.control-arrow:hover, #app-carousel  .control-next.control-arrow:hover {
    background-color: transparent;
}

#app-carousel .control-next.control-arrow:before {
    border-left: 8px solid #737a95;
}

#app-carousel .app-carousel-item {
    width: 100%;
    min-height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #737a95;
    background-color: #e2e2e2;
    padding: 2rem 1.5rem;
    overflow-wrap: anywhere;
}
