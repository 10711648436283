/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

.voice-over .modal-content {
    background-color: transparent;
    color: #fff;
}

.voice-over .modal-content .AudioRecorder {
    display: none;
}

.voice-over .modal-content .vo-message {
    font-size: 24px;
}

.voice-over .modal-content .vo-message.error {
    color: #a90000;
}

.voice-over .modal-content .microphone {
    margin-top: 100px;
    cursor: pointer;
    width: 64px;
}

.voice-over .modal-content .example-actions {
    margin: 15px 40px;
}

.voice-over .modal-content .example-actions .action {
    padding: 10px 0px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
}

.voice-over .modal-content .example-actions .action:hover {
    color: rgba(255, 255, 255, 0.8);
}

.voice-over .modal-content .example-actions .action-image {
    display: inline-table;
    width: 20%;
}

.voice-over .modal-content .example-actions .action-image img {
    width: 16px;
}

.voice-over .modal-content .example-actions .action-title {
    display: inline-table;
    width: 80%;
    text-align: left;
}

.voice-over .modal-content .recognition-error {
    width: 400px;
    position: absolute;
}

.voice-over .modal-content .microphone-access-error {
    margin-top: 100px;
}
