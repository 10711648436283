/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

@keyframes color {
    0% {fill:#1F73B7}
    15% {fill: #1b75be}
    20% {fill: #197bcb}
    25% {fill: #1586e2}
    30% {fill:#18d1f9}
    75%,100% {fill:#1F73B7}
}

.ai-loader-icon {
    position: fixed;
    width: 50px;
    height: 40px;
    right: 12.5rem;
    overflow: hidden;
    z-index: 9999;
    bottom: 2.65rem;
    cursor: pointer;
}

.ai-loader-icon rect,
.ai-loader-icon path {
    animation: color 2s linear infinite;
    transition: all 1s ease-in;
}
