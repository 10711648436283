

.creators-feed-card {
    max-height: 250px;
    min-height: 200px;
    padding-bottom: 10px;
    padding-right: 5px;
    padding-left: 5px;
}

.creators-feed-card .creators-feed-card-bg {
    position: relative;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 3px 3px 4px 1px rgba(0,0,0,.4);

    cursor: pointer;

    /*overflow: hidden;*/
}

.creators-feed-card .creators-feed-card-text {
    position: absolute;
    width: 100%;
    height: 70%;
    padding: 10px 10px 0;
    margin-bottom: 50px;
    color: white;
    text-shadow: 0 0 2px black;
    overflow: hidden;
    text-align: justify;
}

.creators-feed-card .creators-feed-card-bg .creators-feed-card-tip {
    position: absolute;
    bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.creators-feed-card-tip .creators-feed-card-author-avatar {
    margin-right: 5px;
}

.creators-feed-card-tip .creators-feed-card-author-name,
.creators-feed-card-tip .creators-feed-card-duration {
    color: white;
    text-shadow: 1px 1px 3px rgb(0, 0, 0);
}

.creators-feed-card-tip .creators-feed-card-duration {
    position: absolute;
    bottom: 10px;
    right: 10px;
}



.creators-feed-card-bg > .audio-wave {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

.video-volume-icon {
    position: absolute;
    bottom: 10px;
    right: 50px;
}

.video-volume-icon img {
    width: 20px;
}

.creators-feed-card:hover {
    transform: scale(1.10);
    opacity: 1;
    z-index: 100;
}

.creators-feed-card .react-player {
    background-color: black !important;
}

.creators-feed-card .creators-feed-card-fullscreen:hover {
    transform: scale(1.08);
}

.creators-feed-card .creators-feed-card-bg .fullscreen-player-hidden {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.fullscreen-player {
    display: none !important;
}

.creators-feed-card-bg .creators-feed-card-top-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 12px;
    padding-top: 8px;
    transition: opacity .3s ease-in-out;
    opacity: 0;
    pointer-events: none;
}
.creators-feed-card-bg:hover .creators-feed-card-top-bar {
    opacity: 1;
    pointer-events: all;
}
.creators-feed-card-bg .creators-feed-card-top-bar > img {
    width: 24px;
    z-index: 2;
    transition: transform .2s ease-in-out;
    margin-left: 8px;
    cursor: pointer;
    pointer-events: all;
}
.creators-feed-card-bg .creators-feed-card-top-bar > img.creators-edit-btn-icon {
    height: 18px;
}
.creators-feed-card-bg .creators-feed-card-top-bar > img:hover {
    transform: scale(1.08);
}

.creators-feed-card-bg .react-player-thumbnail-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.react-player-thumbnail-wrapper .react-player-thumbnail {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    opacity: 1;
    transition: all .2s ease-in-out;
}
.react-player-thumbnail-wrapper .react-player-thumbnail.display-none {
    /*display: none;*/
    opacity: 0;
}

.react-player-safari-play-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.react-player-safari-play-wrapper:hover .react-player-safari-play-btn {
    opacity: .6;
}
.react-player-safari-play-wrapper .react-player-safari-play-btn {
    transition: opacity ease-in-out .2s;
    opacity: 0;
    width: 15%;
}
