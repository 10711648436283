/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, July 2020
 */

#app-scroll-to-top-button {
    position: fixed;
    right: .5rem;
    bottom: 2.5rem;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FA30FB;
    box-shadow: 0 5px 10px rgba(69, 69, 70, 0.1);
    border-radius: 100px;
    z-index: 9999;
    cursor: pointer;
    outline: none;
}
