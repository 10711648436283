/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

.hashtags-picker {
	background-color: #f2f3f7;
	padding: .5rem;
	display: grid;
	min-height: 40px;
	color: #747b95;
}

.hashtags-picker .hashtags {
	max-height: 12rem;
	display: flex;
	flex-wrap: wrap;
}

.hashtags-picker > div {
	display: inline-block;
}

.new-hashtags-picker {
	background-color: #f2f3f7;
	line-height: 30px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-radius: 4px;
	min-width: 386px;
	min-height: 40px;
	padding-left: 10px;
}

.hashtags-picker-centered > .load-hashtags-wrapper,
.hashtags-picker-centered > .hashtags {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.hide-warning-wrapper {
	display: none !important;
}

.hashtags-picker > .load-hashtags-wrapper {
	margin-bottom: 4px;
}