/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

.modal-alert {
  padding: 70px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-alert .modal-alert-message {
  padding: 20px;
  font-size: 24px;
  letter-spacing: 0.3px;
  color: #747b95;
  text-align: center;
}
.modal-alert .modal-header {
  border-bottom: none;
}

.modal-alert.alertModalTitle .modal-header {
  border-bottom: 1px solid #dee2e6;
}
