/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

.showing-video-guide-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.showing-video-guide-container .paw-container {
    position: relative;
    display: flex;
    font-family: sans-serif;
    cursor: pointer;
}

.showing-video-guide-container .paw-container .paw {
    width: 2rem!important;
    height: 2rem!important;
}

.showing-video-guide-container .paw-container .question {
    position: absolute;
    left: 0.8rem;
    top: 0.825rem;
    color: white;
    font-size: .7rem;
    font-weight: bold;
}

.showing-video-guide-container .paw-pulse {
    position: absolute!important;
    width: 2rem!important;
    height: 2rem!important;
    opacity: 0.3;
    -webkit-animation: paw-pulse 5s infinite ease-in-out;
    -moz-animation: paw-pulse 5s infinite ease-in-out;
    animation: paw-pulse 5s infinite ease-in-out;
    pointer-events: none;
}

.showing-video-guide-container .paw:hover {
    cursor: pointer;
}

@keyframes paw-pulse {
    0% { transform: scale(1); opacity: 0.75}
    35% { transform: scale(1.3); opacity: 0.3}
    50% { transform: scale(1.4); opacity: 0}
    100% { transform: scale(1.4);opacity: 0}
}

@-webkit-keyframes paw-pulse {
    0% { transform: scale(1); opacity: 0.75}
    35% { transform: scale(1.3); opacity: 0.3}
    50% { transform: scale(1.4); opacity: 0}
    100% { transform: scale(1.4);opacity: 0}
}

@-moz-keyframes paw-pulse {
    0% { transform: scale(1); opacity: 0.75}
    35% { transform: scale(1.3); opacity: 0.3}
    50% { transform: scale(1.4); opacity: 0}
    100% { transform: scale(1.4);opacity: 0}
}

@-ms-keyframes paw-pulse {
    0% { transform: scale(1); opacity: 0.75}
    35% { transform: scale(1.3); opacity: 0.3}
    50% { transform: scale(1.4); opacity: 0}
    100% { transform: scale(1.4);opacity: 0}
}

@-o-keyframes paw-pulse {
    0% { transform: scale(1); opacity: 0.75}
    35% { transform: scale(1.3); opacity: 0.3}
    50% { transform: scale(1.4); opacity: 0}
    100% { transform: scale(1.4);opacity: 0}
}
