/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

#not-found-page {
  background-color: #f9fafc;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

#not-found-page .btn-link {
  text-decoration: none;
  color: #18d1f9;
  font-size: 13px;
  font-weight: bold;
}

#not-found-page .box {
  margin-top: 165px;
  text-align: center;
  height: 500px;
}

#not-found-page .message-404 {
  font-size: 230px;
  font-weight: 900;
  color: #747b95;
  text-align: center;
  width: auto;
  height: 280px;
  margin-bottom: 35px;
  opacity: 0.07;
}

#not-found-page .btn-area {
  margin-top: 40px;
}
