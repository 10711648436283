/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, October 2020
 */

#create-single-post-extension {
  height: 100vh;
  width: 100%;
}

#create-single-post-extension .modal-dialog.modal-calendar-post {
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 70px;
}