/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

.calendar-month {
	background-color: #fff;
	box-shadow: 0 5px 10px 0 rgba(69, 69, 70, 0.1);
	position: relative;
}

.calendar-month-volume {
	position: absolute;
	top: 85px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
	display: flex;
}

.calendar-month-volume .calendar-month-volume-color {
	margin: 0 10px;
	font-weight: bold;
}

.calendar-month-volume .calendar-month-volume-low {
	border-bottom: 3px solid #22D3A5;
	color: #22D3A5;
}

.calendar-month-volume .calendar-month-volume-middle {
	border-bottom: 3px solid #FFBD68;
	color: #FFBD68;
}

.calendar-month-volume .calendar-month-volume-high {
	border-bottom: 3px solid #FF79A4;
	color: #FF79A4;
}

.calendar-month-volume .calendar-month-volume-color:first-child {
	margin-left: 20px;
}

.calendar-month .DayPicker {
	width: 100%;
}

.calendar-month .DayPicker-NavBar {
	display: none;
}

.modal-calendar-campaign .calendar-month .DayPicker-wrapper,
.modal-calendar-post .calendar-month .DayPicker-wrapper {
	padding-bottom: 0;
}

.calendar-month .DayPicker-wrapper:focus {
	outline: none;
}

.calendar-month .DayPicker-Month {
	width: 96%;
}

.modal-calendar-campaign .calendar-month .DayPicker-Month,
.modal-calendar-post .calendar-month .DayPicker-Month {
	margin-top: 0;
}

.calendar-month .DayPicker-WeekdaysRow {
	height: 60px;
}

.modal-calendar-campaign .calendar-month .DayPicker-WeekdaysRow,
.modal-calendar-post .calendar-month .DayPicker-WeekdaysRow {
	height: 40px;
}

.calendar-month .DayPicker-Weekday {
	font-size: 10px;
	text-transform: uppercase;
	color: #cfd5da;
	pointer-events: none;
}

.calendar-month .DayPicker-Day {
	font-size: 64px;
	font-weight: 100;
	font-family: ProximaNovaT, sans-serif;
	padding: 5px 0;
}

.calendar-month .DayPicker-Day:hover {
	background-color: transparent!important;
}

.calendar-month .DayPicker-Day:focus {
	outline: none;
}

.calendar-month .DayPicker-Day .calendar-month-day {
	width: 100%;
	height: 80px;
	line-height: 80px;
	display: table;
	position: relative;
}

.calendar-month .DayPicker-Day.DayPicker-Day--today .calendar-month-day .day {
	background-color: #747b95;
	color: #fff;
}

.calendar-month .DayPicker-Day .calendar-month-day .space {
	display: table-cell;
}

.calendar-month .DayPicker-Day .calendar-month-day .space-middle {
	width: 80px;
}

.calendar-month .DayPicker-Day .calendar-month-day.selected-date-range .space {
	background-color: #d4ceff;
}

.calendar-month .DayPicker-Day .calendar-month-day.range-first-day .space.space-left,
.calendar-month .DayPicker-Day .calendar-month-day.range-last-day .space.space-right {
	visibility: hidden
}

.calendar-month .DayPicker-Day .calendar-month-day .day {
	width: 80px;
	color: #747b95;
	border-radius: 50%;
	position: relative;
}

.calendar-month .DayPicker-Day .calendar-month-day.selected .day {
	background-color: #6353ff;
	color: #eeedf9;
}

.calendar-month .DayPicker-Day .calendar-month-day.selected-first .day {
	background-color: #d4ceff;
	border-top-left-radius: 40px;
	border-bottom-left-radius: 40px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}

.calendar-month .DayPicker-Day .calendar-month-day.selected-first .space-right {
	background-color: #d4ceff;
}

.calendar-month .DayPicker-Day .calendar-month-day.selected-last .day {
	background-color: #d4ceff;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	border-top-right-radius: 40px;
	border-bottom-right-radius: 40px;
}

.calendar-month .DayPicker-Day .calendar-month-day.selected-last .space-left {
	background-color: #d4ceff;
}


.calendar-month .DayPicker-Day .calendar-month-day.selectEndDateInPattern .day {
	background-color: transparent;
	color: #747b95;
	position: relative;
}
.calendar-month .DayPicker-Day .calendar-month-day.selectEndDateInPattern .day:after {
	content: '';
	border: 2px dashed #f712ff;
	position: absolute;
	left: -4px;
	border-radius: 50%;
    top: -4px;
    right: 0;
    margin: 0 auto;
    width: 88px;
    height: 88px;
}

.calendar-month .DayPicker-Day .calendar-month-day .color-ball {
	position: absolute;
	font-size: 13px;
	top: 0;
	right: -20px;
	width: 24px;
	height: 24px;
	background-color: #22d3a5;
	line-height: 24px;
	border-radius: 50%;
	color: #fff;
}

.calendar-month .DayPicker-Day.DayPicker-Day--outside {
	pointer-events: none;
	background-color: #f8f7f8;
}

.calendar-month .DayPicker-Day.DayPicker-Day--outside .day {
	color: rgba(116, 123, 149, 0.6);
}

.calendar-month .DayPicker-Day.DayPicker-Day--outside .day .day-load-low {
	background-color: rgba(34, 211, 165, 0.6) !important;
}

.calendar-month .DayPicker-Day.DayPicker-Day--outside .day .day-load-medium {
	background-color: rgba(255, 189, 104, 0.6) !important;
}

.calendar-month .DayPicker-Day.DayPicker-Day--outside .day .day-load-high {
	background-color: rgba(255, 121, 164, 0.6) !important;
}

.color-blind .calendar-month .DayPicker-Day.DayPicker-Day--outside .day .day-load-high {
	background-color: transparent !important;
}

.calendar-month .DayPicker-Caption {
	display: none;
}

.calendar-month .DayPicker-Day .calendar-month-day .color-ball.day-load-low{
	background-color: #22d3a5;
}

.color-blind .calendar-month .DayPicker-Day .calendar-month-day .color-ball.day-load-medium {
	border-radius: 0;
}

.color-blind .calendar-month .DayPicker-Day .calendar-month-day .color-ball.day-load-high {
	background-color: transparent;
	z-index: 0;
}

.color-blind .calendar-month .DayPicker-Day .calendar-month-day .color-ball.day-load-high::after {
	content: '';
	position: absolute;
	top: -8px;
	left: -5px;
	z-index: -1;
	display: block;
	width: 35px;
	height: 35px;
	background: transparent url("../../../images/triangle-red.svg") no-repeat center;
}

.calendar-month .DayPicker-Day .calendar-month-day .color-ball.day-load-medium{
	background-color: #ffbd68;
}

.calendar-month .DayPicker-Day .calendar-month-day .color-ball.day-load-high{
	background-color: #ff79a4;
}

.calendar-month .calendar-angle {
	position: absolute;
	z-index: 1;
	width: 15%;
	height: 20%;
	right: 25px;
	bottom: 0;
}

.calendar-month .hidden {
	visibility: hidden;
}

.calendar-month .day-outside {
	pointer-events: none;
	opacity: 0.66;
}

.calendar-month .day-out-pattern-schedule {
	opacity: 0.25;
}

.calendar-month .DayPicker-Day .calendar-month-day .day .day-events {
	position: absolute;
	width: 240%;
	height: 185%;
	font-size: 13px;
	line-height: 1;
	top: -50%;
	padding-bottom: 25px;
	z-index: 10;
	border-radius: 5px;
	background-color: #ffffff;
	box-shadow: 0 2px 27px 0 rgba(116, 123, 149, 0.42);
}

.calendar-month .DayPicker-Day .calendar-month-day .day .day-event-campaign-container,
.calendar-month .DayPicker-Day .calendar-month-day .day .day-event-calendar-container,
.calendar-month .DayPicker-Day .calendar-month-day .day .day-event-journal-post-container {
	height: 30px;
	margin: 0 13px 5px 5px;
	outline: none;
}

.color-blind .calendar-month .DayPicker-Day .calendar-month-day .day .day-event-campaign-container {
	background: rgba(112,94,255,.2) linear-gradient( to top, rgba(0,0,0, .05) 10%,transparent 11%, transparent 100%);
	background-size: 10px 10px;
}

.color-blind .calendar-month .DayPicker-Day .calendar-month-day .day .day-event-calendar-container {
	background: rgba(24,209,249,.2) linear-gradient( to top, rgba(0,0,0, .05) 10%,transparent 11%, transparent 100%);
	background-size: 10px 10px;
}

.color-blind .calendar-month .DayPicker-Day .calendar-month-day .day .day-event-calendar-container {
	background: rgba(24,209,249,.2) linear-gradient( to top, rgba(0,0,0, .05) 10%,transparent 11%, transparent 100%);
	background-size: 10px 10px;
}

.calendar-month .DayPicker-Day .calendar-month-day .day .day-event-campaign-container {
	border-left: 4px solid #705eff;
	background-color: rgba(112,94,255,.2);
}

.calendar-month .DayPicker-Day .calendar-month-day .day .day-event-calendar-container {
	border-left: 4px solid #18d1f9;
	background-color: rgba(24,209,249,.2);
}

.calendar-month .DayPicker-Day .calendar-month-day .day .day-event-journal-post-container {
	border-left: 4px solid #008000;
	background-color: #23d3a5;
}

.calendar-month .DayPicker-Day .calendar-month-day .day .day-preview {
	width: 43px;
	height: 43px;
	background-color: #22d3a5;
	font-size: 32px;
	font-weight: 300;
	text-align: center;
	color: #fff;
	border-radius: 100%;
	margin: 9px;
	padding-top: 6px;
}

.calendar-month .DayPicker-Day .calendar-month-day .day .day-preview.medium {
	background-color: #ffbd68;
}

.color-blind .calendar-month .DayPicker-Day .calendar-month-day .day .day-preview.medium {
	border-radius: 0;
}

.calendar-month .DayPicker-Day .calendar-month-day .day .day-preview.high {
	background-color: #ff79a4;
}

.color-blind .calendar-month .DayPicker-Day .calendar-month-day .day .day-preview.high {
	padding-left: 8px;
	background-color: transparent;
}

.color-blind .calendar-month .DayPicker-Day .calendar-month-day .day .day-preview.high::after {
	content: '';
	position: absolute;
	top: -10px;
	left: -1px;
	z-index: -1;
	display: block;
	width: 70px;
	height: 70px;
	background: transparent url("../../../images/triangle-red.svg") no-repeat center;
}

.calendar-month .DayPicker-Day .calendar-month-day .day .day-event {
	height: 100%;
	width: 80%;
	max-width: 150px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-align: left;
	padding: 8px 0 0 14px;
	font-family: Montserrat, sans-serif;
	font-size: 12px;
	font-weight: bold;
	color: #747b95;
}

.calendar-month .DayPicker-Day .calendar-month-day .day .day-event-actions {
	display: inline-block;
	float: right;
	margin: -22px 6px 0 0;
	width: 28px;
}

.calendar-month .DayPicker-Day .calendar-month-day .day .day-event-actions img {
	width: 10px;
	height: 10px;
	cursor: pointer;
	margin: 0 4px;
}

.calendar-month .DayPicker-Day.DayPicker-Day--outside {
	pointer-events: auto;
	cursor: pointer;
}

.calendar-month .DayPicker-Day.DayPicker-Day--outside:hover {
	background-color: #f8f7f8 !important;
}
