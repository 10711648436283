/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

#congrats-modal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 4.5rem;
}

#congrats-modal p {
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  color: rgba(116, 123, 149, 0.73);
}

#congrats-modal .congrats-message {
  font-size: 48px;
  font-weight: 300;
  text-align: center;
  color: #22d3a5;
}

#congrats-modal .not-published-message {
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  color: rgba(116,123,149,.73);
}

#congrats-modal .not-published-message .not-published-message-text {
  display: initial;
}

#congrats-modal-footer .btn-link button {
  margin-bottom: 20px;
  text-transform: uppercase;
}

#congrats-modal-header {
  background-color: #ffffff;
  border: solid 1px rgba(116, 123, 149, 0.3);
}

#congrats-modal-header.modal-header {
  padding: 0;
  display: flex;
  align-items: center;
}

#congrats-modal-header div {
  margin-left: 20px;
}

#congrats-modal-header span {
  margin-right: 20px;
}

#congrats-modal .congrats-icon {
  width: 10rem;
  padding: 3rem 0;
}
