/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

.month-picker {
	width: 180px;
	display: flex;
	align-items: center;
}

.month-picker .current-month {
	margin: 0 5px 0 5px;
	font-size: 14px;
	font-weight: bold;
	text-align: center;
	color: #747b95;
	width: 130px;
	text-transform: uppercase;
}

.month-picker .arrow {
	color: #747b95;
	cursor: pointer;
	border: none;
	font-size: 30px;
	width: 10px;
    height: 16px;
    background-repeat: no-repeat;
}

@media screen
and (min-device-width: 1200px)
and (max-device-width: 1600px) {
	.month-picker {
		margin-left: 60px;
	}
}
