
/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, March 2021
 */

.notifications a {
  pointer-events: none;
}

.notifications .notification-icon {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  pointer-events: all;
}

.notifications .notification-icon-point {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: #ff797d;
  position: absolute;
}

.notifications .dropdown-toggle::after {
  content: none;
}

.notifications .dropdown-menu {
  width: 356px;
}

.notifications .dropdown-menu .dropdown-item {
  border-bottom: 1px solid rgba(228, 229, 234, 1);
  padding: 20px 33px 20px 9px;
}

.notifications .notification .notification-point {
  width: 12px;
  display: inline-block;
  padding-top: 3px;
}

.notifications .notification .notification-point .point {
  width: 12px;
  height: 12px;
  border-radius: 100px;
}

.notifications .notification.unread .notification-point .point {
  background-color: #ff797d;
}

.notifications .notification.read .notification-point .point {
  background-color: #ced1da;
}

.notifications .notification .notification-header {
  line-height: 1;
}

.notifications .notification .notification-title-wrap {
  display: flex;
}

.notifications .notification .notification-message {
  width: 103%;
  font-size: 14px;
  padding-left: 20px;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  word-wrap: break-word;
}

.notifications
  .notification
  .notification-message
  .notification-message-container {
  display: flex;
  flex-direction: column;
}

.notifications .notification.unread .notification-title,
.notifications .notification.unread .notification-message {
  color: #747b95;
}

.notifications .notification.read .notification-title,
.notifications .notification.read .notification-message {
  color: #aeb2c1;
}

.notifications .no-notifications {
  text-align: center;
  color: #747b95;
}

.notification .notification-footer {
  color: #aeb2c1;
  font-size: 14px;
  text-align: right;
  padding-top: 5px;
  line-height: 1.4;
  overflow: hidden;
  white-space: initial;
  word-wrap: break-word;
  display: flex;
  justify-content: space-between;
}

.notification .know-more-link {
  color: #18d1f9;
}
