/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, May 2020
 */

#hello-woofy-exclusive-pro-deal {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
}

#hello-woofy-exclusive-pro-deal img {
    cursor: pointer;
    outline: none;
}

#hello-woofy-exclusive-pro-deal span {
    text-align: center;
    width: 75%;
}
