/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, June 2020
 */

.uploaded-file-preview {
    display: flex;
    width: 115px;
    height: 115px;
    border-radius: 4px;
    margin: 0 .5rem .5rem 0;
    background-repeat: no-repeat;
    background-size: cover;
    transform: scale(1);
    transition: 0.85s;
}
.uploaded-file-preview.highlighted-warning {
    border: 1px solid var(--danger);
    box-shadow: 0 0 4px var(--danger);
}

.uploaded-file-preview:hover {
    transform: scale(1.5);
    box-shadow: 5px 5px 10px -4px rgba(0,0,0,0.75);
    z-index: 9999;
}

.uploaded-file-preview .images-container {
    width: 100%;
    height: 100%;
    display: none;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    padding: .7rem;
    margin: .25rem 0;
}

.uploaded-file-preview:hover .images-container {
    display: flex;
}

.uploaded-file-preview .images-container.one-item {
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
}

.uploaded-file-preview .images-container img:hover{
    cursor: pointer;
}

.dropzone-uploaded-files .creators-feed-card {
    max-height: 120px;
    min-height: 120px;
    min-width: 150px;
    transform: none;
}

.dropzone-uploaded-files .creators-feed-card:hover {
    transform: scale(1.5);
    z-index: 999999;
}

.dropzone-uploaded-files .creators-feed-card-bg .creators-feed-card-top-bar {
    justify-content: space-between;
}

.dropzone-uploaded-files .creators-feed-card .creators-feed-card-fullscreen {
    margin-left: auto !important;
}

.dropzone-uploaded-files .creators-feed-card .react-player {
    background-color: black !important;
}