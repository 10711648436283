#app-error-page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-top: 50px;
}
#app-error-page .error-page-text{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #747B95;
    padding-top: 19.5px;
}

#app-error-page .error-page-button {
    width: 30%;
}
