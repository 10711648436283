/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, November 2020
 */

.add-utm-params-modal .add-utm-params-modal-body {
    padding: 1rem 2rem;
}

.add-utm-params-modal .add-utm-params-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-utm-params-modal .add-utm-params-modal-footer .add-utm-params-modal-footer-button {
    width: 110px;
    height: 42px;
}
