/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, June 2020
 */

.confirm-modal {
    text-align: center;
}

.confirm-modal .close {
    display: none;
}

.confirm-modal .modal-content {
    padding: 1rem;
    border: none;
    border-radius: 4px;
    color: #747B95;
    box-shadow: -8px 0 0 0 #7994DC;
    text-align: center;
}

.confirm-modal .modal-body {
    white-space: pre-line;
    padding: 1rem;
}

.confirm-modal .modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.confirm-modal .modal-footer button {
    width: 140px;
    height: 42px;
    font-size: 12px;
}

.confirm-modal .modal-footer .confirm-no.only {
    margin-right: 0;
}

.confirm-modal .warning-message-for-social-networks {
    white-space: pre-wrap;
    text-align: justify;
}
