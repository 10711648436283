/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

#select-calendar-drop-down .btn-drop-down {
  height: 40px;
  min-width: 200px !important;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(69, 69, 70, 0.1);
  z-index: 2;
}

#select-calendar-drop-down .drop-down-title-main {
  font-size: 18px;
  font-weight: 600;
  color: #747b95;
  max-width: 170px;
  float: left;
  overflow: hidden;
  text-overflow: inherit;
}

#select-calendar-drop-down .drop-down-title {
  font-size: 18px;
  color: #747b95;
  float: left;
  width: 100%;
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#select-calendar-drop-down .search-calendar{
  padding: 0 1rem;
  height: 3.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

#select-calendar-drop-down .search-calendar .search-input{
 border-radius: 0;
 border: none;
 border-bottom: 1px solid #10c8ec;
 height: 2.5rem;
 width: 100%;
 padding: 0px 8px;
}

#select-calendar-drop-down .select-calendar-dropdown-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.5rem;
  cursor: pointer;
  padding: 0 0.8rem;
  margin: 0.2rem 0;
  border-radius: 5px;
}

#select-calendar-drop-down .select-calendar-dropdown-item:active {
  outline: none;
  background-color: #fff;
}

#select-calendar-drop-down .select-calendar-dropdown-item:focus,
#select-calendar-drop-down .select-calendar-dropdown-item:hover {
  outline-color: #fff;
}

#select-calendar-drop-down .dropdown-toggle::after {
  width: 9px;
  height: 6px;
  color: #747b95;
  float: right;
  margin-top: 11px;
}

#select-calendar-drop-down .item {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 6px;
  display: inline-block;
  margin-bottom: 1px;
}

#select-calendar-drop-down .add-calendar-dropdown-item {
  font-size: 18px;
  font-weight: 900;
  color: #747b95;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;
  height: 3.5rem;
  cursor: pointer;
}

#select-calendar-drop-down .add-calendar-dropdown-item.error {
  color: #adb2c2;
}

#select-calendar-drop-down .calendar-form {
  display: flex;
  align-items: center;
}

#select-calendar-drop-down .error-message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

#select-calendar-drop-down .form-control {
  width: 135px;
  margin-right: 8px;
}

#select-calendar-drop-down .dropdown-menu {
  width: 240px;
  overflow: hidden !important;
}

#select-calendar-drop-down .current-item {
  background-color: #f0f2f7;
}

#select-calendar-drop-down .input-error {
  border-color: red;
}

#select-calendar-drop-down .add-btn {
  line-height: 27px;
  background-color: #18d1f9;
  border-radius: 15px;
  font-size: 14px;
  border-color: #18d1f9;
  text-align: center;
  cursor: pointer;
  display: block;
  color: #fff;
  width: 100%;
  height: 30px;
}

#select-calendar-drop-down .form-control:focus {
  outline: 0;
  box-shadow: none;
  border-color: #fff;
}

#select-calendar-drop-down .drop-down-actions {
  float: right;
}

#select-calendar-drop-down .drop-down-actions img {
  padding: 0;
}

#select-calendar-drop-down .edit-btn,
#select-calendar-drop-down .remove-btn,
#select-calendar-drop-down .empty-area,
#select-calendar-drop-down .account-reconnect-warning {
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-right: 5px;
}

#calendar-modal .invalid-feedback {
  padding-left: 25px;
}
