/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

.highlight {
  position: absolute;
  z-index: 10;
  cursor: default;
  min-height: 110px;
  min-width: 170px;
  background-color: white;
  border-radius: 15px;
  padding: 15px;
  box-shadow: 0 5px 10px 0 rgba(69, 69, 70, 0.1);
}

.highlight .word,
.highlight .word-out {
  color: #747b95;
  font-size: 14px;
  line-height: 1.2;
  width: 130px;
}

.highlight .word {
  cursor: pointer;
}

.highlight .word-out {
  text-align: center;
  padding-top: 20%;
  word-break: break-word;
}

.highlight .word:hover {
  text-decoration: underline;
}

.highlight .loading {
  padding-left: 10px;
}
