/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

.quotes-component-container {
  min-height: 40px;
  width: 100%;
  margin-bottom: 10px;
  background-color: #f2f3f7;
  padding: 5px 5px 27px 5px;
  position: relative;
  border-radius: 4px;
}

.quotes-component {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #737a95;
  width: 100%;
  min-height: 2.5rem;
}

.quote-row {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 .25rem;
}

.quote-row-center {
  width: calc(100% - 2.5rem);
  margin: 0 auto;
}

.add-quote-to-post {
  color: #21d2f9;
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  bottom: 4px;
  left: 20px;
}

.quote-text {
  line-height: 1;
  color: #747b95;
  font-size: 1rem;
}

.quotes-component .lds-ellipsis div {
  top: 17px;
}
