/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

.modal .library-similarity-modal .modal-body {
    padding: 30px;
}

.modal .library-similarity-modal .library-similarity-modal-suggestion {
    padding-top: 10px;
    margin-bottom: 25px;
}

.modal .library-similarity-modal .library-similarity-modal-buttons {
    display: flex;
    justify-content: center;
}

.modal .library-similarity-modal .library-similarity-modal-button {
    width: 155px;
    margin: 0 20px;
}
