/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, May 2020
 */

.post-account {
    width: 20px;
    height: 20px;
    background-size: cover;
    display: inline-block;
    border-radius: 100px;
    position: relative;
}

.post-account img {
    margin-left: 10px;
    margin-top: 5px;
    background-color: #fff;
    padding: 2px;
    border-radius: 100px;
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.post-account.hootsuite {
    border: 2px solid #000;
}

.post-account .icon-hootsuite {
    position: absolute;
    background-color: #000;
    border-radius: 50%;
    height: 6px;
    width: 6px;
    top: -2px;
    left: -2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.post-account .icon-hootsuite img {
    margin: 0 !important;
    padding: 0 !important;
    height: auto !important;
    width: auto !important;
    max-width: 100%;
    background-color: transparent !important;
}
