/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, February 2021
 */

#redirect-to-page-modal {
    color: #747b95;
    font-size: 1rem;
    text-align: center;
}

#redirect-to-page-modal .redirect-to-page-modal-header {
    height: 3rem;
}

#redirect-to-page-modal .redirect-to-page-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

#redirect-to-page-modal .redirect-to-page-modal-footer .redirect-to-page-modal-footer-button {
    width: 110px;
    height: 42px;
    margin: 0 .5rem;
}
