.extension {
  width: 348px;
  display: flex;
  flex-direction: column;
}

.extension-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #BEBDBD;
  padding-left: 10px;
  margin-bottom: 23px;
}

.extension-header .title {
  font-size: 16px;
  line-height: 19px;
  color: #747B95;
  padding-top: 12px;
  padding-left: 12px;
}

.actions {
  text-align: center;
  padding-top: 7px;
}

.extension #ailoader-wrap{
  right: 0;
  bottom: 0;
  width: 66px;
  height: 24px;
  pointer-events: none;
}

.extension .ai-loader-icon {
    right: 5rem;
    height: 30px;
    width: 40px;
}
