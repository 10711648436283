#subscription-update-modal .avatar {
    width: 125px;
    height: 125px;
    border-radius: 100%;
    background-size: cover;
    display: inline-block;
    margin: 0 .5rem;
}

#subscription-update-modal .purple {
    background-color: #FF4F99;
}
#subscription-update-modal .blue {
    background-color: #6427fa;
}
#subscription-update-modal .cyan {
    background-color: #00bcff;
}

#subscription-update-modal .modal-body button {
    border-radius: 4px;
    margin: 40px 25px 40px 25px;
    font-weight: 700;
    font-size: 18px;
}

#subscription-update-modal .modal-content {
    border-radius: 25px;
}

#subscription-update-modal .modal-body {
    padding: 2rem;
}

#subscription-update-modal .centered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: -10px;
}

#subscription-update-modal .top-aligned {
    margin-top: 25px;
}

#subscription-update-modal h3 {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    line-height: 34px;
}

#subscription-update-modal h5 {
    text-align: center;
    font-size: 20px;
    line-height: 24px;
}

#subscription-update-modal h3, #subscription-update-modal h5, #subscription-update-modal span {
    color: #6F768E;
}

#subscription-update-modal .card {
    box-shadow: 0px 14px 20px #E3E6EE;
    border: 0;
    padding: 30px 20px 0px 20px;
    height: 200px;
    margin: 0px 10px;
    border-radius: 7px;
}

#subscription-update-modal .card button {
    border: 0;
    margin-top: auto;
}

#subscription-update-modal .card span {
    font-size: 18px;
}

#subscription-update-modal span {
    text-align: center;
    line-height: 22px;
}

#subscription-update-modal .modal-content {
    position: relative;
    overflow: hidden;
}

#subscription-update-modal .modal-content img:not(.avatar){
    position: absolute;
    bottom: 0;
}

#subscription-update-modal .dogen {
    z-index: 100;
    left: 55px;
}

#subscription-update-modal .modal-footer {
    margin: 25px auto 55px auto;
}

#subscription-update-modal .modal-footer button.btn {
    border: 1px solid;
    border-radius: 4px;
    padding: 16px 18px;
    z-index: 5;
}

#subscription-update-modal .response-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    width: 80%;
    align-self: center;
}

#subscription-update-modal .response-info-container.error {
    color: #fa30fb;
}

#subscription-update-modal .response-info-container.success {
    color: #22d3a5;
}

#subscription-update-modal .response-info-message {
    font-size: 18px;
}

#subscription-update-modal .response-info-message:first-of-type {
    padding-top: 20px;
}

#subscription-update-modal .response-info-notes {
    font-size: 14px;
    color: #705EFF;
    font-weight: bold;
}

#subscription-update-modal .response-info-container-img {
    width: 35%;
    background-color: #fa30fb;
    padding: 10px;
    border-radius: 100%;
}

#subscription-update-modal .user-subscription-info-steps-container {
    height: 155px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#subscription-update-modal .user-subscription-info-steps-plan-info-container {
    margin-left: 120px;
}
