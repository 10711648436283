/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, May 2020
 */

#remind-to-install-woofy-app-modal .remind-to-install-woofy-app-modal-body {
    padding-top: 2rem;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    color: #747b95;
}

#remind-to-install-woofy-app-modal .remind-to-install-woofy-app-modal-body .remind-to-install-woofy-app-modal-body-link {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    padding-top: 1rem;
}

#remind-to-install-woofy-app-modal .remind-to-install-woofy-app-modal-body .remind-to-install-woofy-app-modal-body-link .apple-icon {
    width: 6.5rem;
    height: 2rem;
}

#remind-to-install-woofy-app-modal .remind-to-install-woofy-app-modal-body .remind-to-install-woofy-app-modal-body-link .android-icon {
    height: 3rem;
}

#remind-to-install-woofy-app-modal .remind-to-install-woofy-app-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

#remind-to-install-woofy-app-modal .remind-to-install-woofy-app-modal-footer .remind-to-install-woofy-app-modal-footer-button {
    width: 155px;
    height: 46px;
    font-size: 12px !important;
}

.coming-soon-tooltip.tooltip .arrow::before {
    border-bottom-color: transparent;
    border-right-color: #07a3ed;
}