/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, November 2020
 */

.add-utm-to-link-utm-params-detect {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 .75rem;
}

.add-utm-to-link-utm-params-detect .check-mark {
    padding-right: .25rem;
}

.add-utm-to-link {
    color: #4285f4;
    outline: none;
}

.add-utm-to-link:hover {
    cursor: pointer;
    text-decoration: underline;
}
