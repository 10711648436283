.caption_generation_modal {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  min-width: 940px;
}

.caption_generation_section {
  width: 100%;
  background-color: white;
  outline: none;
}

.caption_section {
  padding: 12px 0px;
  width: 100%;
}

.submit-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-buttons button {
  margin-left: 10px;
}

.caption_generation_section .response_item {
  margin: 18px 0px;
}

.caption_generation_section .response_btn {
  margin-top: 12px;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  min-width: 940px;
}

.form-control {
  background-color: rgb(242, 243, 247);
  border-radius: 0px;
}
