/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

#call-to-action-dropdown {
  display: flex;
  justify-content: flex-start;
  margin-left: 16px;
}

#call-to-action-dropdown .btn-drop-down {
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgb(69 69 70 / 10%);
  width: auto;
  color: #747b95;
  cursor: pointer;
  display: flex;
  border-radius: 100px;
  height: 30px;
  padding: 1px 10px;
  margin-top: 6px;
}
.call-to-action-dropdown-duration .btn-drop-down {
  width: 65px!important;
}

.call-to-action-dropdown-unit .btn-drop-down {
  width: 100px!important;
}

#call-to-action-dropdown .dropdown-toggle::after {
  margin-top: 6px;
  color: #747b95;
}

#call-to-action-dropdown .drop-down-toggle {
  font-size: 14px;
  font-weight: 600;
  padding-right: 32px;
  color: #747b95;
  max-width: 205px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#call-to-action-dropdown .dropdown-item {
  cursor: pointer;
  width: 100%;
  font-weight: normal;
  padding: 10px 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: #747b95;
}

#call-to-action-dropdown .dropdown-item:focus {
  outline: none;
}

#call-to-action-dropdown .drop-down-add-calendar {
  font-weight: 900;
  color: #747b95;
  float: left;
}

#call-to-action-dropdown .calendar-form {
  display: flex;
  align-items: center;
}

#call-to-action-dropdown .form-control {
  width: 150px;
  margin-right: 8px;
}

#call-to-action-dropdown .calendar-form button:focus {
  outline: none;
}

#call-to-action-dropdown .dropdown-menu {
  width: 240px;
  box-shadow: 0 5px 10px 0 rgba(69, 69, 70, 0.1);
  border: none;
}

#call-to-action-dropdown .current-item {
  background-color: #ddd9d9;
}

#call-to-action-dropdown .input-error {
  border-color: red;
}

#call-to-action-dropdown .add-btn {
  line-height: 30px;
  background-color: #18d1f9;
  border-radius: 15px;
  font-size: 14px;
  border-color: #18d1f9;
  text-align: center;
  cursor: pointer;
  display: block;
  color: #fff;
  width: 100%;
  height: 30px;
}

#call-to-action-dropdown .dropdown-item:active {
  background-color: #ffffff;
}



#call-to-action-dropdown .form-control:focus {
  outline: 0;
  box-shadow: none;
  border-color: #fff;
}

.cta-drop-down-div {
  display: flex;
  justify-content: flex-start;
  text-align: right;
  height: fit-content;
  flex-direction: column;
}

.cta-url-div {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  flex-direction: column;
}

.gmb-offer-redeem-online-url-input {
  background-color: #f2f3f7;
  border: none;
  margin-right: 6px;
  padding-right: 0 !important;
}
