.autocomplete__suggestion {
  opacity: 0.6;
}

.autocomplete__suggestion::before {
  content: attr(data-suggestion);
}

.autocomplete__tab-button::after {
  content: 'tab';
  margin-left: 5px;
  padding: 0 3px;
  border-radius: 2px;
  font-size: 12px;
  border: 1px solid rgba(116, 123, 149, .5);
}

.autocomplete__swipe-button::after {
  content: 'right swipe';
  margin-left: 5px;
  padding: 0 3px;
  border-radius: 2px;
  font-size: 12px;
  border: 1px solid rgba(116, 123, 149, .5);
}
