.extension-settings {
  display: flex;
  flex-direction: column;
  width: 348px;
  padding-left: 23px;
  padding-right: 23px;
}

.setting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  height: 30px;
}

.sub-setting {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  height: 30px;
}

.sub-setting .sub-title {
  font-size: 10px;
}

.setting-toggle {
  display: flex;
}

.setting .setting-toggle {
  align-self: center;
  height: 30px;
} 

.sub-setting .setting-toggle {
  align-self: flex-end;
  height: 30px;
} 


.sub-setting .branch {
  height: 25px;
  width: 5px;
  border-left: 2px solid #18d1f9;
  border-bottom: 2px solid #18d1f9;
  border-radius: 0 0 0 40%;
  margin-left: 3px
}

.extension-settings .ext-switch {
  width: 40px;
  height: 17px;
  position: relative;
  top: 8px;
}
.extension-settings .ext-switch.on {
  background: #18d1f9;
}

.extension-settings .ext-switch.disabled {
    background: #8195a7;
    pointer-events: none;
}

.extension-settings .ext-switch .switch-toggle {
  width: 15px;
  height: 15px;
}

.open-drop-down-button circle {
  stroke: #C4C4C4;
  position: relative;
  stroke-width: 1;
}

.ext-btn-single-post {
  border: 1px solid #18D1F9;
  border-radius: 50px;
  font-size: 14px;
  line-height: 17px;
  color: #18D1F9;
  font-weight: bold;
  box-shadow: 0 5px 10px rgba(69, 69, 70, 0.1);
  padding: 12px 34px;
}

.ext-btn-single-post:hover {
  color: #18D1F9;
  text-decoration: none;
}

.ext-btn-add-content {
  background: #18D1F9;
  box-shadow: 0 5px 10px rgba(69, 69, 70, 0.1);
  border-radius: 100px;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  font-weight: bold;
  padding: 12px 34px;
}

.ext-btn-add-content:hover {
  color: #FFFFFF;
  text-decoration: none;
}