/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, October 2020
 */

nav.nav-calendar button {
    top: 45%;
    position: absolute;

    z-index: 9;
    cursor: pointer;
    border: none;

}

nav.nav-calendar button img {
    position: relative;
    height: 60px;
    opacity: .5;

}

nav .nav-calendar-arrow.prev {
    left: 15px;
}

nav .nav-calendar-arrow.next {
    right: 15px;
    transform: rotate(180deg);
}

nav .nav-calendar-arrow.next.week,
nav .nav-calendar-arrow.next.day {
    right: 5px;
}

nav .nav-calendar-arrow.prev.week,
nav .nav-calendar-arrow.prev.day {
    left: -10px;
}
