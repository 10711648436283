/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

.calendar-image-uploader > div {
	padding: .75rem;
}

.calendar-image-uploader .dropzone-add-files {
	font-size: 14px;
	font-weight: bold;
	color: rgba(116, 123, 149, 0.5);
	text-transform: uppercase;
	display: flex;
	justify-content: center;
}

.calendar-image-uploader .dropzone-add-files span {
	cursor: pointer;
}

.calendar-image-uploader .dropzone-uploaded-files {
	display: flex;
	flex-wrap: wrap;
	min-height: 110px;
	justify-content: center;
	cursor: pointer;
}

.calendar-image-uploader .dropzone-uploaded-files #plus {
	cursor: pointer;
	width: 15%;
}

.calendar-image-uploader .image-editing {
	right: 40px;
	bottom: 10px;
	width: 23px;
	height: 23px;
	padding: 0;
	border-radius: 50px;
	background-color: #3ebfca;
	cursor: pointer;
}

.calendar-image-uploader .image-editing img {
	margin-left: 5px;
	margin-bottom: 5px;
}

.calendar-image-uploader {
	position: relative;
}

.calendar-image-uploader .icons-wrapper {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.calendar-image-uploader .icons-wrapper div {
	margin-left: .5rem;
}
