/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, September 2020
 */

.content-container-attach {
    display: flex;
    justify-content: space-evenly;
}

.input-text {
    background-color: #f2f3f7;
    border: none;
    margin-bottom: 0.5rem;
    width: 250px;
}

.get-text-from-url-modal {
    box-shadow: -8px 0 0 0 #7994DC;
    max-width: 600px;
}

.get-text-from-url-modal .get-text-from-url-modal-header {
    border: none;
}

.get-text-from-url-modal .get-text-from-url-modal-body {
    text-align: center;
    color: #747B95;
    font-size: 18px;
}

.get-text-from-url-modal .get-text-from-url-modal-body .get-text-from-url-modal-body-text {
    display: flex;
    padding: 1rem 0;
    align-items: center;
    justify-content: center;
}

.get-text-from-url-modal .get-text-from-url-modal-body .get-text-from-url-modal-body-alert {
    font-size: 1rem;
    padding: 0.5rem;
}

.get-text-from-url-modal .get-text-from-url-modal-body .image-to-text {
    width: 100%;
    height: 100%;
    max-width: 20rem;
    max-height: 20rem;
}

.get-text-from-url-modal .get-text-from-url-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.get-text-from-url-modal .get-text-from-url-modal-footer-button {
    width: 110px;
    height: 42px;
    box-shadow: 0 5px 10px rgba(69, 69, 70, 0.1);
    color: #747B95;
    margin: 0 1rem;
}

.get-text-from-url-modal .get-text-from-url-modal-footer-button:disabled {
    opacity: 1;
    background-color: #C4C4C4;
    color: #ffffff;
}

.get-text-from-url-modal .get-text-from-url-modal-footer .download-file-button {
    cursor: pointer;
}

.picker-popover {
    position: absolute;
    z-index: 2;
}
.picker-cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.picker-swatch {
    margin-left: 5px;
    padding: 5px;
    background: #fff;
    border-radius: 100%;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: pointer;
}

.picker-color {
    width: 20px;
    height: 20px;
    border-radius: 100%;
}

.get-text-from-url-modal-body-alert {
    font-size: 1rem;
    padding: 0.5rem;
}

.select-wp-category-toggle {
    width: 15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4rem;
    color: #747B95;
    box-shadow: 0 5px 10px 0 rgba(69, 69, 70, 0.1);
}
