/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

.modal-loader.campaign-confirm .modal-content .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 12px 48px;
}

.modal-loader.campaign-confirm .modal-content .modal-body .loader-container {
    width: 100%;
    padding: 1rem 0;
}

.modal-loader.campaign-confirm  .modal-content {
    border: none;
    border-radius: 4px;
}


.modal-loader.campaign-confirm .modal-content .modal-body .loader-text {
    font-size: 17px;
    color: #747B95;
}

.modal-loader.campaign-confirm .modal-content .modal-body .loader-spinner,
.modal-loader.campaign-confirm .modal-content .modal-body .loader-art-board {
    margin-bottom: 5px;
}


.modal-loader.campaign-confirm .loading {
    display: flex;
    justify-content: center;
    align-items: center;
}
