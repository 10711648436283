/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

.avatar {
    width: 35px;
    height: 35px;
    border-radius: 100px;
    margin-right: 15px;
}

.avatar-social-icon {
    width: 25px;
    height: 25px;
    margin-top: 24px;
    margin-left: -30px;
    background-color: #fff;
    border-radius: 100px;
    padding: 4px;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, .1);
}

.account-name {
    width: 210px;
    max-width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 10px;
}

.account-name span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 130px;
    max-width: 130px;
}
