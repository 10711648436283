/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, September 2020
 */


.get-text-from-url-modal {
    box-shadow: -8px 0 0 0 #7994DC;
}

.get-text-from-url-modal .get-text-from-url-modal-header {
    border: none;
}

.get-text-from-url-modal .get-text-from-url-modal-body {
    text-align: center;
    color: #747B95;
    font-size: 18px;
}

.get-text-from-url-modal .get-text-from-url-modal-body .get-text-from-url-modal-body-text {
    display: flex;
    padding: 1rem 0;
    align-items: center;
    justify-content: center;
}

.get-text-from-url-modal .get-text-from-url-modal-body .get-text-from-url-modal-body-alert {
    font-size: 1rem;
    padding: 0.5rem;
}

.get-text-from-url-modal .get-text-from-url-modal-body .image-to-text {
    margin: 1rem;
    width: 100%;
    height: 100%;
    max-width: 20rem;
    max-height: 20rem;
}

.get-text-from-url-modal .get-text-from-url-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.get-text-from-url-modal .get-text-from-url-modal-footer-button {
    width: 110px;
    height: 42px;
    box-shadow: 0 5px 10px rgba(69, 69, 70, 0.1);
    color: #747B95;
    margin: 0 1rem;
}

.get-text-from-url-modal .get-text-from-url-modal-footer-button:disabled {
    opacity: 1;
    background-color: #C4C4C4;
    color: #ffffff;
}

.get-text-from-url-modal .get-text-from-url-modal-footer .download-file-button {
    cursor: pointer;
}
