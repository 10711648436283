/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, September 2020
 */

.not-posted-post-warning-icon {
    outline: none;
    cursor: default;
}

.not-posted-post-warning-icon .retry-button-text {
    padding-right: .5rem;
    outline: none;
}

.not-posted-post-warning-icon .not-posted-post-icon {
    cursor: pointer;
}

.retry-btn {
    width: 100px;
    height: 42px;
    color: red;
}
