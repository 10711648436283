/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

.list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.list-item {
    position: relative;
    padding: 20px 0;
    padding-left: 29px;
    padding-right: 20px;
    margin-bottom: 7px;
    font-size: 16px;
    line-height: 20px;
    color: rgb(116, 123, 149);
    cursor: pointer;
}

.list-item:last-child {
    margin-bottom: 0;
}

.list-item img {
    float: right;
}

.list-item .item {
    overflow: hidden;
    max-width: 90%;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.list-item .action-container {
    display: flex;
}

.list-item::before {
    content: "";
    position: absolute;
    z-index: 40;
    left: 0;
    top: 0;
    width: 7px;
    height: 60px;
    box-shadow: 0 0 8px 0 transparent;
}

.list-item::after {
    content: "";
    position: absolute;
    z-index: 40;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 1px;
    box-shadow: 0 0 8px 0 transparent;
}

.list-item:hover {
    background-color: rgba(116, 123, 149, 0.1);
}

.list-item.active,
.list-item:active {
    font-weight: 700;
    background-color: rgba(116, 123, 149, 0.1);
}

.list-item-0::before {
    background-color: #00cfff;
}

.list-item-1::before {
    background-color: #d6d6d6;
}

.list-item-2::before {
    background-color: #ff57ce;
}

.list-item-3::before {
    background-color: #426aff;
}

.list-item-4::before {
    background-color: #f8e71c;
}

.list-item-5::before {
    background-color: #ff9f00;
}

.list-item-6::before {
    background-color: #ff7284;
}

.list-item-7::before {
    background-color: #62cdff;
}

.list-item-8::before {
    background-color: #00cfff;
}

.list-item-9::before {
    background-color: #d6d6d6;
}

.list-item-10::before {
    background-color: #ff57ce;
}

.list-item-11::before {
    background-color: #426aff;
}

.list-item-12::before {
    background-color: #f8e71c;
}

.list-item-13::before {
    background-color: #ff9f00;
}

.list-item-14::before {
    background-color: #ff7284;
}

.list-item-15::before {
    background-color: #62cdff;
}

.list-item.active::before {
    opacity: 1;
}

.list-item-0.active::after {
    background-color: #00cfff;
    box-shadow: 0 0 8px 0 rgba(24, 209, 249, 0.24);
}

.list-item-1.active::after {
    background-color: #d6d6d6;
    box-shadow: 0 0 8px 0 rgba(214, 214, 214, 0.24);
}

.list-item-2.active::after {
    background-color: rgba(255, 87, 206, 0.3);
    box-shadow: 0 0 8px 0 rgba(255, 87, 206, 0.24);
}

.list-item-3.active::after {
    background-color: rgba(66, 106, 255, 0.3);
    box-shadow: 0 0 8px 0 rgba(66, 106, 255, 0.24);
}

.list-item-4.active::after {
    background-color: rgba(248, 231, 28, 0.3);
    box-shadow: 0 0 8px 0 rgba(248, 231, 28, 0.24);
}

.list-item-5.active::after {
    background-color: rgba(255, 159, 0, 0.3);
    box-shadow: 0 0 8px 0 rgba(255, 159, 0, 0.24);
}

.list-item-6.active::after {
    background-color: rgba(255, 114, 132, 0.3);
    box-shadow: 0 0 8px 0 rgba(255, 114, 132, 0.24);
}

.list-item-7.active::after {
    background-color: rgba(98, 205, 255, 0.81);
    box-shadow: 0 0 8px 0 rgba(98, 205, 255, 0.24);
}

.list-item-8.active::after {
    background-color: #00cfff;
    box-shadow: 0 0 8px 0 rgba(24, 209, 249, 0.24);
}

.list-item-9.active::after {
    background-color: #d6d6d6;
    box-shadow: 0 0 8px 0 rgba(214, 214, 214, 0.24);
}

.list-item-10.active::after {
    background-color: rgba(255, 87, 206, 0.3);
    box-shadow: 0 0 8px 0 rgba(255, 87, 206, 0.24);
}

.list-item-11.active::after {
    background-color: rgba(66, 106, 255, 0.3);
    box-shadow: 0 0 8px 0 rgba(66, 106, 255, 0.24);
}

.list-item-12.active::after {
    background-color: rgba(248, 231, 28, 0.3);
    box-shadow: 0 0 8px 0 rgba(248, 231, 28, 0.24);
}

.list-item-13.active::after {
    background-color: rgba(255, 159, 0, 0.3);
    box-shadow: 0 0 8px 0 rgba(255, 159, 0, 0.24);
}

.list-item-14.active::after {
    background-color: rgba(255, 114, 132, 0.3);
    box-shadow: 0 0 8px 0 rgba(255, 114, 132, 0.24);
}

.list-item-15.active::after {
    background-color: rgba(98, 205, 255, 0.81);
    box-shadow: 0 0 8px 0 rgba(98, 205, 255, 0.24);
}

.categories-modal-header {
    border-top: 5px solid #757a94;
    border-bottom: none;
    color: #757a94;
}

.categories-modal-header .modal-title {
    display: flex;
    align-items: center;
}

.categories-modal-header .modal-title span {
    padding-right: 20px;
}

.categories-modal-footer {
    padding: 16px 0 !important;
    margin: -10px auto 30px auto;
}

.categories-modal-footer .save-category-btn {
    min-width: 150px;
    text-transform: uppercase;
}

.categories-modal-footer .disabled {
    border: 0;
    background-color: #757a94;
}

.color-blind .save-category-btn {
    background: #18d1f9 url("../../../images/triangle.svg") repeat;
    background-size: 10px 10px;
}

.color-blind .save-category-btn:hover {
    background-color: #10c8ec;
}

.categories-modal-footer .delete-category-btn {
    margin-right: 20px;
    min-width: 150px;
    text-transform: uppercase;
}

.add-category label {
    margin-left: 1.5rem;
}

.error-modal .modal-content {
    color: red;
    text-align: center;
    padding-bottom: 25px;
}

.error-modal .modal-content .modal-header {
    border-bottom: none;
    margin-bottom: -25px;
}
