/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, January 2021
 */

.add-title-to-gmb-post-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  font-weight: bold;
  color: rgba(116, 123, 149, 0.5);
}

.add-title-to-gmb-post-container .add-title-to-gmb-post-title {
  padding: 0 0.5rem;
}

.add-title-to-gmb-post-container .add-title-to-gmb-post-checkbox {
  cursor: pointer;
}

.add-title-to-gmb-post-container .add-title-to-gmb-post-input-container {
  position: relative !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #747b95;
  background: #f2f3f7;
  border-radius: 5px;
  margin: 0.5rem 0;
  padding-left: 0.5rem;
}

.add-title-to-gmb-post-container
  .add-title-to-gmb-post-input-container
  .input-post-title {
  color: #747b95;
  background: #f2f3f7;
  border: none;
  outline: none;
}

.add-title-to-gmb-post-container
  .add-title-to-gmb-post-input-container
  .title-length-counter {
  padding-top: 0.5rem;
  font-weight: normal;
}

.chat-gpt-icon {
  position: absolute;
  right: 12px;
  bottom: 12px;
  cursor: pointer !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(16, 163, 127, 0.6);
    height: 30px;
    width: 30px;
}
