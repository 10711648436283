/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

.add-content-modal .add-content-modal-title {
  display: flex;
  align-items: center;
}

.add-content-modal {
  max-width: 945px;
}

.add-content-modal .modal-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 2.5rem;
}

.add-content-modal .modal-title img {
  width: 66px;
  height: 61px;
}

.add-content-modal .modal-body {
  padding: 1rem;
}

.add-content-modal .input-field {
  position: relative;
}

.add-content-modal .modal-body textarea {
  height: 153px;
}

.add-content-modal .post-date-options > label {
  margin-bottom: 0;
}

.add-content-modal .modal-footer {
  padding-top: 0;
}

.add-content-modal .modal-footer button {
  width: 155px;
  height: 42px;
  text-transform: uppercase;
}

.color-blind .modal-footer .btn-light {
  background: #fff linear-gradient( to top, rgba(0,0,0, .05) 10%,transparent 11%, transparent 100%);
  background-size: 10px 10px;
}

.color-blind .modal-footer .btn-primary {
  background: #18d1f9 url("../../../images/triangle.svg") repeat;
  background-size: 12px 12px;
}

.color-blind .modal-footer .btn-primary:hover {
  background-color: #10c8ec;
}

.add-content-modal .custom-control-label {
   cursor: pointer;
}

.add-content-modal .social-line {
  display: none !important;
  float: right;
  font-weight: bold;
  color: #747b95;
}

.add-content-modal .social-line .post-similar-line {
  padding-right: 10px;
}

.add-content-modal .post-social-icon {
  cursor: pointer;
}

.add-content-modal .post-social-icon-no-active {
  opacity: 0.5;
  cursor: pointer;
}

.add-content-modal .social-line img {
  margin-left: 9px;
}

.add-content-modal .custom-select {
  margin-bottom: 10px;
  width: 60%;
  display: block;
  cursor: pointer;
}

.add-content-modal .form-control:focus {
  background-color: #f2f3f7;
  border: 0;
  box-shadow: none;
}

.add-content-modal .modal-body .input-counter {
	position: absolute;
	bottom: 1px;
	left: 1%;
  font-size: 14px;
  font-weight: 600;
  color: rgba(116, 123, 149, 0.6);
  width: 25px;
  background-color: #f2f3f7;
  border-radius: 10%;
  text-align: left;
}

.add-content-modal .switch {
  margin-left: 13px;
  height: 14px;
  width: 34px;
}

.add-content-modal .switch.on {
  background-color: #00e7ff;
  border: none;
}

.add-content-modal .switch .switch-toggle {
  bottom: 4px;
  left: -1px;
  width: 20px;
  height: 20px;
}

.add-content-modal .switch.on .switch-toggle {
  background-color: #18d1f9;
  left: 14px;
  bottom: 3px;
  border: none;
}

.add-content-modal .textarea {
  height: 153px;
  border-radius: 4px;
  background-color: #f2f3f7;
  border: none;
  resize: none;
  padding: 15px 20px;
  color: #747b95;
  font-family: ProximaNova, serif;
  font-size: 16px;
  font-weight: 600;
}

.add-content-modal .textarea a {
  cursor: pointer;
  color: #18d1f9;
}

.add-content-modal .textarea .element-hashtag {
  color: #22d3a5;
}

.similarity-library-post-modal .modal-body {
  padding: 16px 38px 16px 38px !important;
}

.similarity-library-post-modal .modal-body .check {
  width: 200px;
  padding: 25px;
}

#modal-add-content .input-field .header-translator-container {
  display: flex;
  flex-direction: column;
}

#modal-add-content .input-field .header-translator-container .header-translator {
  display: flex;
}

#modal-add-content .input-field .header-translator-container .header-translator #add-content-modal-translator {
  width: 100%;
}

#modal-add-content .input-field .header-translator-container .header-translator .header-label {
  width: 100%;
}

#modal-add-content .modal-body .hashtags-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#modal-add-content #add-content-modal-hashtags {
  margin: .25rem;
}

.add-content-add-utm-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0;
}
