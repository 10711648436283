/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

.similarity-block .similarity-block-label {
    color: #426AFF;
    margin: 5px 0 15px 0;
}

.similarity-block .similarity-block-medias {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
}

.similarity-block .similarity-block-media {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 5px;
}

.similarity-block .similarity-block-media.active-account {
    color: #03C8FF;
}

.similarity-block .similarity-block-media.cursor-pointer {
    cursor: pointer;
}

.similarity-block .similarity-block-media-name:first-letter {
    text-transform: uppercase;
}

.similarity-block .similarity-block-media-name {
    margin-bottom: 7px;
}

.similarity-block .similarity-block-media-accounts {
    padding: 10px 15px;
    background-color: #F8F9FA;
}

.similarity-block .similarity-block-media-account {
    display: flex;
    font-size: 14px;
}

.similarity-block .similarity-block-media-account:not(:last-child) {
    margin-bottom: 8px;
}
.similarity-block .similarity-block-account-name {
    width: 260px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.similarity-block .similarity-block-account-score {
    width: 80px;
    text-align: right;
    color: #426AFF;
}