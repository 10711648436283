/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

.voice-over .modal-content {
  text-align: center;
}

.header {
  height: 270px;
}

.svg-loader {
  position: relative;
}

.svg-loader_animate-main
{
  animation: goBackGrow 7s infinite;
}
.svg-loader_animate-second
{
  animation: goSecondGrow 7s infinite;
}

.svg-loader_animate-third
{
  animation: goThirdGrow 7s infinite;
}

.svg-loader_animate-fourth
{
  animation: goFourthGrow 7s infinite;
}

.svg-loader_grow {
  stroke-dasharray: 500;
  stroke-dashoffset: -500;
  stroke-linejoin: round;
  stroke-linecap: round;
  transform: translate(130px, 130px);
}

circle {
  stroke: #fff;
  position: relative;
  stroke-width: 3;
}

@keyframes goBackGrow {
  0% {
    stroke-dashoffset: -500;
    transform: translate(130px, 130px) rotate(45deg);
    opacity: 0.25;
    r:45px;
  }
  15% {
    stroke-dashoffset: 0;
    transform: translate(130px, 130px) rotate(-90deg);
    opacity: 1;
  }
  23% {
    r:43px;
    stroke-width: 3;
  }
  39% {
    r: 53px;
    stroke-width: 6;
  }
  65% {
    r:45px;
    stroke-width: 3;
  }
  66% {
    r: 40px;
  }
  69% {
    r: 44px;
  }
  70% {
    r: 40px;
  }
  90% {
    r: 53px;
    stroke-width: 6;
  }
  97% {
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
    r:45px;
    stroke-width: 3;
    opacity: 0;
  }
}

@keyframes goSecondGrow {
  0% {
    r:45px;
    stroke-dashoffset: 0;
    opacity: 0;
  }
  16% {
    r:45px;
    stroke-width: 3;
    opacity: 0;
  }
  17% {
    transform: translate(130px, 130px) scale(1);
    opacity: 0.5;
  }
  30% {
    transform: translate(130px, 130px) scale(3);
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 0;
    transform: translate(130px, 130px) scale(3);
    opacity: 0;
  }
}

@keyframes goThirdGrow {
  0% {
    r:44px;
    stroke-dashoffset: 0;
    opacity: 0;
  }
  64% {
    opacity: 0;
  }
  65% {
    transform: translate(130px, 130px) scale(1);
    stroke-width: 3;
    opacity: 0.5;
  }
  77% {
    transform: translate(130px, 130px) scale(3);
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 0;
    transform: translate(130px, 130px) scale(3);
    opacity: 0;
  }
}

@keyframes goFourthGrow {
  0% {
    r:44px;
    stroke-dashoffset: 0;
    opacity: 0;
  }
  64% {
    opacity: 0;
  }
  65% {
    transform: translate(130px, 130px) scale(1);
    stroke-width: 3;
    opacity: 0.5;
  }
  82% {
    transform: translate(130px, 130px) scale(3);
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 0;
    transform: translate(130px, 130px) scale(3);
    opacity: 0;
  }
}
