/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

.modal-dialog.similarity-calendar-post-modal .modal-content {
    padding: 20px 17px;
    border: none;
    border-radius: 4px;
    color: #747B95;
    box-shadow: -8px 0 0 0 #7994DC;

}

.modal-dialog.similarity-calendar-post-modal .similarity-calendar-post-modal-info-label {
    text-align: left;
    color: #426AFF;
}

.modal-dialog.similarity-calendar-post-modal .similarity-calendar-post-modal-buttons {
    padding-top: 25px;
}

.modal-dialog.similarity-calendar-post-modal .similarity-calendar-post-modal-button {
    width: 10rem;
    margin: .5rem;
}

.modal-dialog.similarity-calendar-post-modal .similarity-block-label,
.modal-dialog.similarity-calendar-post-modal .similarity-block-account-name {
    text-align: left;
}

.modal-dialog.similarity-calendar-post-modal .similarity-block-account-name {
    width: 215px;
}

.similarity-message-block {
    font-size: 15px;
}
