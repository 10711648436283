/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, November 2020
 */

.link-preview {
    word-wrap: anywhere;
}

.utm-params-form-label {
    font-size: 1.25rem;
    display: flex;
}

.utm-params-form-label .utm-params-form-required {
    font-size: .75rem;
    color: red;
}

.utm-params-form-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;
}

.utm-params-form-button-container .utm-params-form-button {
    width: 110px;
    height: 42px;
    margin: 0 .5rem;
}
