/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

.predictive-linguistics-scroll {
    position: absolute!important;
    margin: 0 1rem!important;
    width: calc(100% - 2rem)!important;
    height: calc(100% - 5rem)!important;
}

.predictive-linguistics-desktop .predictive-linguistics-tab {
    margin-left: 5px;
    padding: 0 3px;
    border-radius: 2px;
    font-size: 12px;
}

.predictive-linguistics-desktop {
    word-wrap: break-word;
    white-space: pre-wrap;
}
