.info-section {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-right: 23px;
  padding-left: 23px;
}

.info-section-title {
  font-weight: bold;
}

.info-section-title, .feature {
  font-size: 16px;
  line-height: 24px;
  color: #747B95;
}

.button-section {
  margin: 20px 0 40px;
  display: flex;
  flex-direction: row;
}

.btn-login, .btn-login:hover, .btn-login:visited, .btn-login:active {
  color: #18D1F9;
  padding: 13px 52px;
}

.btn-signup, .btn-signup:hover, .btn-signup:visited, .btn-signup:active {
  color: #fff;
  background-color: #18D1F9;
  margin-left: 20px;
  padding: 13px 45px;
}

.btn-login, .btn-login:hover, .btn-login:visited, .btn-login:active,
.btn-signup, .btn-signup:hover, .btn-signup:visited, .btn-signup:active {
  border: 1px solid #18D1F9;
  box-shadow: 0 5px 10px rgba(69, 69, 70, 0.1);
  border-radius: 100px;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
}