/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

/** Proxima Nova **/

@font-face {
    font-family: 'ProximaNova-Black';
    src: url('./Mark Simonson - Proxima Nova Alt Black-webfont.woff2') format('woff2'),
         url('./Mark Simonson - Proxima Nova Alt Black-webfont.woff') format('woff'),
         url('./Mark Simonson - Proxima Nova Alt Black-webfont.ttf') format('truetype'),
         url('./Mark Simonson - Proxima Nova Alt Black-webfont.svg#proxima_nova_altblack') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'ProximaNova-Extrabld';
    src: url('./Mark Simonson - Proxima Nova Alt Bold-webfont.eot');
    src: url('./Mark Simonson - Proxima Nova Alt Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('./Mark Simonson - Proxima Nova Alt Bold-webfont.woff2') format('woff2'),
         url('./Mark Simonson - Proxima Nova Alt Bold-webfont.woff') format('woff'),
         url('./Mark Simonson - Proxima Nova Alt Bold-webfont.ttf') format('truetype'),
         url('./Mark Simonson - Proxima Nova Alt Bold-webfont.svg#proxima_nova_altbold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'ProximaNova';
    src: url('./Mark Simonson - Proxima Nova Alt Regular-webfont.eot');
    src: url('./Mark Simonson - Proxima Nova Alt Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('./Mark Simonson - Proxima Nova Alt Regular-webfont.woff') format('woff'),
         url('./Mark Simonson - Proxima Nova Alt Regular-webfont.ttf') format('truetype'),
         url('./Mark Simonson - Proxima Nova Alt Regular-webfont.svg#proxima_nova_altregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'ProximaNova-Light';
    src: url('./Mark Simonson - Proxima Nova ScOsf Thin-webfont.eot');
    src: url('./Mark Simonson - Proxima Nova ScOsf Thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('./Mark Simonson - Proxima Nova ScOsf Thin-webfont.woff') format('woff'),
         url('./Mark Simonson - Proxima Nova ScOsf Thin-webfont.ttf') format('truetype'),
         url('./Mark Simonson - Proxima Nova ScOsf Thin-webfont.svg#proxima_nova_scosfthin') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'ProximaNova-Semibold';
    src: url('./Mark Simonson - Proxima Nova Semibold-webfont.eot');
    src: url('./Mark Simonson - Proxima Nova Semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('./Mark Simonson - Proxima Nova Semibold-webfont.woff2') format('woff2'),
         url('./Mark Simonson - Proxima Nova Semibold-webfont.ttf') format('truetype'),
         url('./Mark Simonson - Proxima Nova Semibold-webfont.svg#proxima_nova_ltsemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'ProximaNovaT';
    src: url('./Mark Simonson - Proxima Nova Thin-webfont.eot');
    src: url('./Mark Simonson - Proxima Nova Thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('./Mark Simonson - Proxima Nova Thin-webfont.woff2') format('woff2'),
         url('./Mark Simonson - Proxima Nova Thin-webfont.woff') format('woff'),
         url('./Mark Simonson - Proxima Nova Thin-webfont.ttf') format('truetype'),
         url('./Mark Simonson - Proxima Nova Thin-webfont.svg#proxima_nova_ltthin') format('svg');
    font-weight: normal;
    font-style: normal;

}
/* latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/lato/v23/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/lato/v23/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh6UVSwaPGQ3q5d0N7w.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}