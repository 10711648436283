/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

.hide-resizable-box .react-resizable-handle.react-resizable-handle-se {
  display: none;
}

.editor {
  position: relative;
  height: 100%;
  border-radius: 4px;
  background-color: #f2f3f7;
  border: none;
  resize: none;
  color: #747b95;
  font-family: ProximaNova, sans-serif;
  font-size: 16px;
  cursor: text;
}

.editor .draftJsToolbar__toolbar__dNtBH {
  display: flex;
  position: absolute;
  left: 0;
  top: -5rem;
  z-index: 9;
}

.editor .draftJsToolbar__button__qi1gf {
  background-color: #f2f3f7;
  color: #747b95;
  border: none;
  font-weight: bold;
}

.editor .draftJsToolbar__active__3qcpF {
  color: #2996da;
}

.editor .draftJsToolbar__button__qi1gf.draftJsToolbar__active__3qcpF svg {
  fill: #2996da;
}

.editor .draftJsToolbar__button__qi1gf svg {
  fill: #747b95;
}

.editor .draftJsToolbar__toolbar__dNtBH input {
  width: 30rem;
}

.editor .draftJsHashtagPlugin__hashtag__1wMVC {
  color: #22d3a5;
}

.editor .draftJsLinkifyPlugin__link__2ittM {
  cursor: pointer;
  color: #18d1f9;
}

.editor .predictive-linguistics-mobile {
  position: absolute;
  top: -50px;
  right: 166px;
  left: 166px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editor .predictive-linguistics-mobile .mobile-content {
  padding: 4px 18px;
  border-radius: 16px;
  background-color: #fff;
  border: 2px solid #92c7ff;
  cursor: pointer;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
  display: inline-block;
}

.editor .predictive-linguistics-wrap {
  height: 90px;
  width: 100%;
  color: rgba(116, 123, 149, 0.5);
}

.editor .predictive-linguistics-wrap.hidden {
  visibility: hidden;
}

.editor .predictive-linguistics-desktop .predictive-linguistics-tab {
  border: 1px solid rgba(116, 123, 149, 0.5);
}

.chat-gpt-icon {
  position: absolute;
  right: 12px;
  bottom: 12px;
  cursor: pointer !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset,
    0px 0px 8px rgba(16, 163, 127, 0.6);
  height: 30px;
  width: 30px;
  border-radius: 10px;
}
