/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, September 2020
 */

.get-images-from-url-modal .modal-content {
    min-width: 350px;
    box-shadow: -8px 0 0 0 #7994DC;
}

.get-images-from-url-modal .get-images-from-url-modal-header {
    border: none;
    justify-content: center;
}

.get-images-from-url-modal .get-images-from-url-modal-header .modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.get-images-from-url-modal .get-images-from-url-modal-header .get-image-from-URL-modal-icon {
    width: 2.5rem;
    height: 2.5rem;
}

.get-images-from-url-modal .get-images-from-url-modal-body {
    text-align: center;
}

.get-images-from-url-modal .get-images-from-url-modal-body .get-images-from-url-modal-body-alert {
    font-size: 1rem;
    padding: 0.5rem;
}

.get-images-from-url-modal .get-images-from-url-modal-body-input {
    background-color: #F2F3F7;
    border-radius: .4rem;
    width: 100%;
    margin-right: 2rem;
}

.get-images-from-url-modal .get-images-from-url-modal-body .image-from-open-graph {
    margin: 1rem;
    width: 100%;
    height: 100%;
    max-width: 20rem;
    max-height: 20rem;
}

.get-images-from-url-modal .get-images-from-url-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.get-images-from-url-modal .get-images-from-url-modal-footer-button {
    width: 195px;
    height: 42px;
    margin-inline: 1rem;
    background-color: #18D1F9;
    box-shadow: 0 5px 10px rgba(69, 69, 70, 0.1);
    color: #ffffff;
}

.get-images-from-url-modal .get-images-from-url-modal-footer-button:disabled {
    opacity: 1;
    background-color: #C4C4C4;
    color: #ffffff;
}

.get-images-from-url-modal .get-images-from-url-modal-footer .download-file-button {
    cursor: pointer;
}

.image-list-wrap {
    max-height: 450px;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
}

.image-list-item {
    cursor: pointer;
    min-width: 195px;
    width: 22%;
    height: 150px;
    background-size: cover;
    margin: 13px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 14px 0 rgba(116, 123, 149, 0.09);
}

.image-list-item.active {
    border: solid 6px #18d1f9;
}

.image-list-item.no-active{
    opacity: 0.4;
}

.art-board {
    margin: 0 auto;
    margin-bottom: 15px;
}

.no-images {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}
