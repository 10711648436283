/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

#dropdown-categories .btn-drop-down {
  margin-top: 10px;
  width: 100%;
  height: 44px;
  font-size: 16px;
  color: rgba(116, 123, 149, 0.5);
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  background-color: white;
  border-radius: 100px;
  box-shadow: 0 5px 10px 0 rgba(69, 69, 70, 0.1);
  position: relative;
}

#dropdown-categories .dropdown-toggle::after {
  margin-top: 12px;
  position: absolute;
  right: 5%;
  top: 7px;
}

#dropdown-categories .dropdown-toggle {
  padding-left: 30px;
  color: #747b95;
  text-transform: capitalize;
}

#dropdown-categories .dropdown-toggle button {
  outline: none;
}

#dropdown-categories .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
  outline: none;
}

#dropdown-categories .dropdown-toggle-box {
  width: 360px;
  display: inline-block;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

#dropdown-categories .drop-down-toggle {
  white-space: nowrap;
  text-overflow: clip;
}

#dropdown-categories .drop-down-menu {
  width: 95%;
  margin: 0 2.5%;
  border: 0;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
}

#dropdown-categories .dropdown-item.active,
.dropdown-item:active {
  background-color: #18d1f9;
}

#dropdown-categories .dropdown-item {
  display: flex;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  cursor: pointer;
  color: #747b95;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
  align-items: center;
}

#dropdown-categories .drop-down-select-categories-title {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 75%;
  vertical-align: middle;
}

.checkBoxOff,
.checkBoxOn {
  padding: 15px;
}

#dropdown-categories .add-new-category {
  height: 56px;
  padding-left: 71px;
  position: relative;
  border-bottom: 1px solid rgba(196, 196, 196, 0.2);
}

#dropdown-categories .add-new-category span {
  font-size: 30px;
  position: absolute;
  top: 6px;
  left: 41px;
}

#dropdown-categories .add-new-category:hover {
  background-color: #f8f9fa;
  color: #18d1f9;
}
