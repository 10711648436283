/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

.language-translator-container {
    width: 100%;
    display: flex;
    align-items: center;
    /*justify-content: space-around;*/
    justify-content: flex-end;
    font-size: 14px;
    color: #4285f4;
    margin-bottom: .5rem;
}

.language-translator-container .current-language {
    width: 50%;
    display: flex;
    align-items: center;
}

.language-translator-container #languageSelectPopover {
    cursor: pointer;
}

.language-translator-container #languageSelectPopover:hover {
    cursor: pointer;
}

.language-translator-container .all-languages {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 49rem;
    z-index: 9;
    background-color: #ffffff;
    padding: 10px;
}

.language-translator-container .all-languages .all-languages-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 17.5%;
    height: 30px;
    padding: 5px;
    color: #000000;
    border-radius: 5px;
}

.language-translator-container .all-languages .all-languages-item:hover {
    cursor: pointer;
    background-color: silver;
}