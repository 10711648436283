/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

#dropdown-accounts .dropdown-header {
  padding: .6rem 1rem .6rem 1.5rem;
  text-align: right;
  font-size: 14px;
  color: #747B95;
  font-weight: 600;
}

#dropdown-accounts .btn-drop-down {
  width: auto !important;
  height: 50px !important;
  border-radius: 30px;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(69, 69, 70, 0.1);
}

#dropdown-accounts .dropdown-toggle::after {
  margin-top: 6%;
  position: absolute;
  right: 25%;
}

#dropdown-accounts .dropdown-toggle {
  padding: 0 25px;
  color: #747b95;
}

#dropdown-accounts .dropdown-toggle button{
  outline: none;
}

#dropdown-accounts .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
  outline: none;
}

#dropdown-accounts .dropdown-toggle-box {
  width: 220px;
  display: inline-block;
  text-align: left;
  text-transform: none;
  line-height: 40px;
}

#dropdown-accounts .drop-down-toggle {
  white-space: nowrap;
  text-overflow: clip;
}

#dropdown-accounts .drop-down-menu {
  width: 525px;
  border: 0;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  border-radius: 13px;
}

#dropdown-accounts .gmb-disabled-accounts {
  opacity: 0.5;
}

#dropdown-accounts .dropdown-item.active, .dropdown-item:active {
  background-color: #18d1f9;
}

#dropdown-accounts .dropdown-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  cursor: pointer;
  color: #747b95;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 60px;
  text-transform: none;
  border-bottom: 1px solid rgba(196, 196, 196, 0.2);
  border-top: 1px solid rgba(196, 196, 196, 0.2);
  position: relative;
}

#dropdown-accounts .dropdown-item.facebookAccount {
  padding-left: 43px;
}

#dropdown-accounts .checkBoxOff,
#dropdown-accounts .checkBoxOn {
  padding: 15px 10px 15px 15px ;
  margin-left: -25px;
}

#dropdown-accounts .avatar-preview {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  margin-right: -12px;
}

#dropdown-accounts .avatar-preview.hootsuite-account {
  border: 3px solid #000;
}

#dropdown-accounts .after-avatars-count {
  margin-left: 30px;
  vertical-align: sub;
}

#dropdown-accounts .dropdown-item .avatar {
  width: 35px;
  height: 35px;
  border-radius: 100px;
  margin-right: 15px;
}
#dropdown-accounts .dropdown-item .avatar.hootsuite-account {
  border: 3px solid #000;
}
#dropdown-accounts .social-title {
  line-height: 35px;
  height: 37px;
}
#dropdown-accounts .avatar-social-icon {
  width: 25px !important;
  height: 25px !important;
  margin-top: 24px;
  margin-left: -30px;
  background-color: #fff;
  border-radius: 100px;
  padding: 4px;
  box-shadow: 0 2px 9px 0 rgba(0,0,0,.1);
}
#dropdown-accounts .avatar-hootsuite-icon {
  height: 16px;
  width: 16px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 6px;
  left: 38px;
  background: #000;
  border-radius: 50%;
}

#dropdown-accounts .avatar-hootsuite-icon img {
  max-height: 16px;
}

#dropdown-accounts .child:not(.is-hootsuite) {
    background: rgba(196, 196, 196, 0.2);
}

#dropdown-accounts .child.grand {
  background: rgba(196, 196, 196, 0.4);
}

#dropdown-accounts .child .avatar-social-icon {
    width: 43px;
    height: 43px;
    margin-top: inherit;
    background-color: transparent;
    border-radius: 50%;
    padding: 4px;
    box-shadow: none;
    position: absolute;
    top: 23px;
    left: 62px;
    margin-left: 24px;
}

#dropdown-accounts .child.is-hootsuite .avatar-social-icon {
  left: 25px;
}

#dropdown-accounts .child.grand .avatar-social-icon {
  left: 82px;
}

#dropdown-accounts .child:not(.is-hootsuite) .checkBoxOff,
#dropdown-accounts .child:not(.is-hootsuite) .checkBoxOn {
  padding-right: 50px;
}

#dropdown-accounts .child.grand .checkBoxOff,
#dropdown-accounts .child.grand .checkBoxOn {
  padding-right: 4rem;
}

#dropdown-accounts .child .account-name {
  width: 230px;
  max-width: 230px;
}

#dropdown-accounts .type {
  font-size: 14px;
  line-height: 17px;
  color: #747B95;
  text-transform: capitalize;
  margin-right: 10px;
  font-weight: 600;
}

#dropdown-accounts .account-name {
  display: inline-block;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
}
#dropdown-accounts .reconnect{
  position: relative;
  right: -10px;
  padding: 3px 12px;
  color: #fff;
  border: none;
  border-radius: 16px;
  background-color: #cc4449;
  font-size: 14px;
  text-decoration: none;;
}

#dropdown-accounts .avatar-social-icon-preview {
  width: 23px;
  height: 23px;
  position: absolute;
  margin-top: 22px;
  margin-left: 0;
  background-color: #fff;
  border-radius: 100px;
  padding: 4px;
  box-shadow: 0 2px 9px 0 rgba(0,0,0,.1);
}

#dropdown-accounts .hootsuite-icon {
  position: absolute;
  height: 16px;
  width: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  background-image: url('../../../images/icon-white-hootsuite.svg');
  background-position: center center;
  background-size: 16px;
  background-repeat: no-repeat;
  border-radius: 50%;
}

.dropdown-empty-item {
  padding: 20px;
  text-align: center;
  color: #747b95;
}

.dropdown-empty-item a {
  color: #747b95;
  text-decoration: underline;
}

.rss-category-checkbox.facebookAccount {
  pointer-events: none;
}

.collapse-icon {
  padding: 15px 10px 15px 15px;
  margin-left: -30px;
}

.account-name-full-text {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  text-align: justify;
  height: 1rem;
  border-radius: .25rem;
  background-color: #fbfbfb;
  font-size: .75rem;
  font-weight: bold;
  z-index: 999;
  box-shadow: 0 5px 10px 0 rgba(69, 69, 70, 0.1);
  width: auto;
  padding: 0 .25rem;
  color: #39d1f8;
}