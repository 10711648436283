/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

.video-guide-iframe {
    width: 640px;
    height: 360px;
    z-index: 9000;
    position: fixed;
    left: 1%;
    bottom: 1%;
    box-shadow: 1.5px 1.5px 5px #747b95;
    background-color: #f2f3f7;
}

.video-guide-iframe .close-icon {
    display: flex;
    width: 20px;
    height: 20px;
    align-items: center;
    padding: .7rem;
    justify-content: center;
    z-index: 9001;
    background-color: #ffffff;
    color: #000000;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 40px;
}

.video-guide-iframe .close-icon:hover {
    cursor: pointer;
}
