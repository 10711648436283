/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, October 2020
 */

#main-menu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5rem;
    background-color: #ffffff;
}

#main-menu .navbar-brand {
    font-size: 24px;
    color: #18d1f9;
    font-family: ProximaNova-Extrabld, sans-serif;
    padding: 0 2rem 0 1rem;
    margin: 0;
}

#main-menu .navbar-brand:hover {
    color: #18d1f9;
}

#main-menu .nav-item {
    margin: 0 .5rem;
}

#main-menu .nav-item.active {
    border-bottom: 5px solid #18d1f9;
}

#main-menu .nav-item a {
    text-transform: uppercase;
    font-size: 14px;
}

#main-menu .nav-item a span {
    padding-right: .5rem;
}

#main-menu .navbar-nav .nav-link,
#main-menu .navbar-nav .active > .nav-link {
    height: 3rem;
    color: #747b95;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
}

#main-menu .logo {
    height: 45px;
}

#main-menu .navbar-avatar {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-size: cover;
    display: inline-block;
    margin: 0 .5rem;
}

#main-menu .navbar-avatar.active {
    border: 1px solid rgba(116, 123, 149, 0.3);
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

#main-menu .nav-right {
    margin: 0!important;
    display: flex;
    align-items: center;
}

#main-menu .nav-right .hello-woofy-exclusive-deal {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

#main-menu .nav-right .hello-woofy-exclusive-deal a {
    line-height: 1.5rem;
    font-size: 14px;
}

#main-menu .nav-right .dropdown-menu {
    box-shadow: 0 5px 10px 0 rgba(69,69,70,.1);
    border: none;
}

#main-menu .nav-right .dropdown-menu .dropdown-item {
    cursor: pointer;
    outline: none;
}

#main-menu .nav-right .dropdown-menu .dropdown-item img {
    padding-right: .75rem;
}

#main-menu .nav-right .dropdown-menu .settings-button img {
    width: 2.25rem;
    height: 2.25rem;
}

#main-menu .nav-right .dropdown-menu .settings-button .png-icon {
    width: 2.25rem;
    height: auto;
}

#main-menu .nav-right .dropdown-menu .settings-button:active {
    background-color: transparent;
    color: #18d1f9;
}

#main-menu .nav-right .dropdown-menu .logout-button:active {
    background-color: transparent;
    color: #EB5757;
}

#main-menu .nav-right .drop-down-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#main-menu .search-icon {
    width: 16px;
    height: 16px;
    margin-top: 26px;
    margin-right: 20px;
    cursor: pointer;
}

@media (max-width: 1400px) {
    #main-menu .navbar-brand {
        padding: 0 10px;
    }
    #main-menu .navbar-nav .nav-link,
    #main-menu .navbar-nav .active > .nav-link {
        padding: 0 10px;
    }
}

@media (max-width: 1200px) {
    #main-menu .navbar-brand {
        padding: 0 .5rem;
    }
    #main-menu .navbar-nav .nav-link,
    #main-menu .navbar-nav .active > .nav-link {
        padding: 0 .5rem;
    }
}

@media screen
and (min-device-width: 1200px)
and (max-device-width: 1600px) {
    #main-menu .nav-item {
        margin: 0;
    }

    #main-menu .navbar-nav .nav-link, #main-menu .navbar-nav .active > .nav-link {
        padding: 0 1rem;
    }
}

.hello-woofy-exclusive-deal .deal-btn{
    height: 44px;
    width: 180px;
    border: 3px solid white;
    border-radius: 22px;
    background-color: #fa30fb;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    /* box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px; */
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .hello-woofy-exclusive-deal .deal-btn .right img{
    height: 30px;
    transform: rotate(90deg);
    filter: invert(0) sepia(0%) saturate(0%) hue-rotate(65deg) brightness(277%) contrast(106%);
  }

  .hello-woofy-exclusive-deal .deal-btn .left {
    font-size: 11px;
    line-height: 15px;
    font-style: italic;
    color: white;
    font-family: 'Montserrat';
    font-weight: 600;
  }

  .hello-woofy-exclusive-deal {
      margin-top: -10px;
  }
