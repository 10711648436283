/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, October 2020
 */

.text-length-counter-container {
    display: flex;
    position: absolute;
    bottom: 1px;
    font-size: 14px;
    font-weight: 600;
    color: rgba(116, 123, 149, 0.6);
    background-color: #f2f3f7;
    border-radius: 10%;
    width: auto;
    padding: 0 .5rem;
    text-align: left;
}

.text-length-counter-container .text-length-counter {
    display: flex;
}

.text-length-counter-container .twitter-length-counter {
    padding-left: .5rem;
    display: flex;
}
