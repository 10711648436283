/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

.modal-dialog.modal-calendar-post {
	margin: 0.75rem auto;
}

.modal-calendar-post {
	max-width: 945px;
}

.modal-calendar-post .day-picker {
	padding-right: 10px;
	padding-left: 10px;
}

.modal-calendar-post .modal-header {
	height: 100%;
	position: relative;
	padding: 4px 1rem;
}

.modal-calendar-post .modal-header #modal-header-picker {
	padding: 0 1rem;
}

.modal-calendar-post .modal-title {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 2.5rem;
}

.modal-calendar-post .modal-title img {
	width: 66px;
    height: 61px;
}

.modal-calendar-post .modal-body {
	padding-top: 9px;
	padding-bottom: 5px;
}

.modal-calendar-post .modal-body textarea {
	height: 153px;
}

.modal-calendar-post .form-control:focus {
  background-color: #f2f3f7;
  border: none;
  box-shadow: none;
}

.modal-calendar-post .input-field {
	position: relative;
}

.modal-calendar-post .input-field .header-translator  {
	display: flex;
}

.modal-calendar-post .input-field .header-translator .header-label {
	width: 100%;
	margin: 0;
}

.modal-calendar-post .modal-body .date-labels-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.modal-calendar-post .modal-body .date-labels-container label {
	display: flex;
}

.modal-calendar-post .custom-radio {
	margin-bottom: 5px;
}

.modal-calendar-post .modal-footer {
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.modal-calendar-post .modal-footer button {
	width: 155px;
	height: 42px;
	text-transform: uppercase;
}

.modal-calendar-post .modal-footer .calendar-account-picker {
	margin-right: auto;
}

.modal-calendar-post .post-date {
	font-size: 25px;
	letter-spacing: 0.2px;
	line-height: 15px;
	padding-top: 17px;
	font-family: ProximaNovaT, sans-serif;
	font-weight: 300;
	margin-left: 15px;
	color: #747b95;
	outline: none;
}

.modal-calendar-post .post-date:hover {
	cursor: pointer;
}

.modal-calendar-post .post-date-box {
	display: flex;flex-direction: column;
}

.modal-calendar-post .post-date-shedule {
	margin-top: .5rem;
}

.modal-calendar-post .post-date-options {
	display: flex;
}

.modal-calendar-post .category-select{
	display: block !important;
}

.modal-calendar-post .post-date-options .inputs-container {
	width: 42.5%;
	display: flex;
	flex-direction: column;
}

.modal-calendar-post .post-date-options .dates-container {
	width: 57.5%;
	display: flex;
	flex-direction: column;
}

.modal-calendar-post .post-date-options .dates-container #select-hour {
	font-size: .8rem;
	font-weight: bold;
}

.modal-calendar-post .custom-control-label {
	cursor: pointer;
}

.modal-calendar-post #dropdown-accounts .dropdown-toggle::after {
	margin-top: 7%;
	right: 5%;
}

.modal-calendar-post .modal-footer #dropdown-accounts .drop-down-menu {
	width: 500px;
}

.modal-calendar-post .textarea {
	height: 153px;
	border-radius: 4px;
	background-color: #f2f3f7;
	border: none;
	resize: none;
	padding: 15px 20px;
	color: #747b95;
	font-family: ProximaNova, serif;
	font-size: 16px;
	font-weight: 600;
}

.modal-calendar-post .textarea a {
	cursor: pointer;
	color: #18d1f9;
}

.modal-calendar-post .textarea .element-hashtag {
	color: #22d3a5;
}

.modal-calendar-post .submit-buttons {
	display: flex;
}

.modal-calendar-post .submit-buttons button {
	margin-left: 10px;
}

.modal-calendar-post-header {
	display: flex;
	align-items: center;
}

.hashtags-title-container {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
}

.calendar-post-modal-body-bottom-column {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.create-post-add-utm-container {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.hidden-alert {
	display: none;
}

.quotes-container {
	display: flex;
	justify-content: space-between;
}

.label-youtube {
	margin-right: 5px;
}

.youtube-post-modal {
	padding-right: 0;
}

.youtube-post-modal-select {
	padding-right: 5px;
	padding-left: 7px;
}