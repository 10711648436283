.video-thumbnail-modal-body {
    display: flex;
    flex-direction: column;
}

.video-thumbnail-modal-footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.use-thumbnail-btn {
    margin: 0 auto;
    width: 100%;
    max-width: 150px;
}

.capture-thumbnail-video {
    max-width: 450px;
    max-height: 600px;
    margin: 0 auto;
}

.upload-image-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 8px;
    max-width: 280px;
    word-wrap: normal;
    white-space: nowrap;
    color: #747b95;
    font-size: 16px;
    font-weight: 600;
}
