/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

.image-suggestion img {
    cursor: pointer;
}

.image-suggestion-modal {
    min-width: 550px;
}

.image-suggestion-wrap {
    max-height: 80vh !important;
    overflow-y: scroll;
}

.image-suggestion-modal .modal-header {
    height: 100%;
    position: relative;
    padding: 4px 1rem;
}

.image-suggestion-modal .modal-header .modal-title img {
    width: 66px;
    height: 61px;
}

.image-suggestion-modal .image-suggestion-item {
    width: 255px;
    height: 172px;
    background-size: cover;
    margin: 13px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 14px 0 rgba(116, 123, 149, 0.09);
}

.image-suggestion-modal .image-suggestion-item.active {
    border: solid 6px #18d1f9;
}

.image-suggestion-modal .image-suggestion-item.no-active {
    opacity: 0.4;
}

.image-suggestion-modal .modal-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 16px 32px;
}

.image-suggestion-modal .modal-body .no-images {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    padding: 50px 0;
}

.image-suggestion-modal .modal-body .no-images .art-board {
    margin-bottom: 15px;
}

.image-suggestion-modal .modal-body .no-images,
.calendar-image-uploader .no-images {
    text-align: center;
    color: #747B95;
}

.image-suggestion-modal .submit-buttons button {
    margin-right: 15px;
    text-transform: uppercase;
    padding-left: 25px;
    padding-right: 25px;
}

.image-suggestion-modal .modal-title {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.image-suggestion-modal .submit-buttons .select-images {
    width: 175px;
}

.image-suggestion-modal .image-suggestion-search {
    position: relative;
    display: inline-block;
    width: 310px;
    margin-left: auto;
    vertical-align: middle;
}

.image-suggestion-modal .image-suggestion-search .form-group {
    margin: 0;
}

.image-suggestion-modal .image-suggestion-search-input {
    display: inline-block;
    vertical-align: middle;
    width: 270px;
    height: 42px;
    padding: 11px 20px;
    padding-left: 40px;
    font-size: 14px;
    line-height: 1;
    font-weight: bold;
    color: #747b95;
    border-radius: 100px;
    border: solid 1px rgba(116, 123, 149, 0.3);
    box-sizing: border-box;
    background-color: transparent;
}

.image-suggestion-modal .modal-header .image-suggestion-search .image-suggestion-search-icon {
    position: absolute;
    z-index: 40;
    left: 13px;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 14px;
}

.image-suggestion-wrap .image-suggestion-tab-button{
    padding: 12px 0px;
    background-color: white;
    box-shadow: 0 5px 20px 0 rgba(116, 123, 149, 0.09) !important;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    font-size: 16px !important;
    color: #747b95 !important;
    font-weight: 700;
}

.image-suggestion-wrap .active-tab{
    background-color: #39d1f8 !important;
    color: white !important;
}

.banner-bear-section .image-suggestion-item {
    cursor: pointer;
    width: 255px;
    height: 172px;
    background-size: cover;
    margin: 13px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 14px 0 rgba(116, 123, 149, 0.09);
}


.tag-select{
    word-wrap: normal;
    display: inline-block;
    vertical-align: middle;
    width: 170px;
    margin-right: 24px;
    height: 42px;
    padding: 0 12px;
    font-size: 14px;
    line-height: 1;
    font-weight: bold;
    color: #747b95;
    border-radius: 100px;
    border: solid 1px rgba(116, 123, 149, 0.3);
    box-sizing: border-box;
    background-color: transparent;
    margin-left: 44px !important;
}