/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, December 2020
 */

.shopify-instruction-modal .shopify-instruction-modal-header {
    height: 3rem;
    padding: 0.5rem;
    align-items: center;
    justify-content: center;
}

.shopify-instruction-modal .shopify-instruction-modal-body {
    padding: 2rem;
    text-align: justify;
}

.shopify-instruction-modal .shopify-instruction-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.shopify-instruction-modal .shopify-instruction-modal-footer button {
    width: 110px;
    height: 42px;
}
