.bear_modal_inner {
  min-height: 100vh;
  min-width: 100vw;
  background-color: white;
}

.bear_modal .bear_title {
  color: #747b95;
  font-size: 24px;
  font-weight: 500;
}

.bear_modal .bear_input {
  border-right: 1px solid #dee2e6;
  padding-right: 14px;
  max-height: 90vh;
  overflow-x: hidden;
}

.bear_modal .bear_modal_inner .bear_modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-bottom: 1px solid #dee2e6;
}

.bear_modal .bear_modal_body {
  padding: 10px 18px;
}

.bear_modal .bear_modal_body .banner_item {
  margin: 32px 0px;
  width: 80%;
  margin-left: 24px;
}

.bear_modal .banner_item .banner_label {
  margin-bottom: 8px;
  font-size: 1rem;
}

.bear_modal .banner_img {
  width: 80%;
}

.bear_modal .banner_img input {
  background-color: hsla(0, 0%, 76.9%, 0.2);
  border-radius: 100px;
  border: solid 1px #f2f3f7;
  height: 42px;
  padding-left: 14px;
}

/* .bear_modal .banner_img input[type="file"] {
    background-color: hsla(0,0%,76.9%,.2);
    border-radius: 100px;
} */

.bear_modal .banner_input_field {
  color: transparent;
  cursor: pointer;
  width: -webkit-fill-available;
}

.bear_modal .banner_input_field::-webkit-file-upload-button {
  visibility: hidden;
}

.bear_modal .banner_input_field::before {
  content: "Upload image";
  padding-left: 14px;
  font-size: 1rem;
  color: #707090;
  height: 42px;
  display: flex;
  align-items: center;
  width: -webkit-fill-available;
}

.bear_modal .banner_text {
  width: 100%;
}

.bear_modal .banner_text input {
  width: 100%;
  height: 42px;
  padding-left: 14px;
}

.bear_modal .bear_submit {
  margin-top: 32px;
}

.bear_modal .bear_submit button {
  background-color: #18d1f9;
  border-radius: 100px;
  font-weight: 700;
  font-size: 14px;
  border: 1px solid #18d1f9;
  color: white;
  outline: none;
  height: 42px;
  width: 114px;
}

.bear_modal .bear_submit button:disabled {
  background-color: hsla(0, 0%, 76.9%, 0.2);
  color: #212529;
}

.bear_modal .bear_preview {
  width: 90%;
  margin: 0 auto;
}

.bear_modal .bear_preview img {
  width: 100%;
  mix-blend-mode: multiply;
}

.bear_modal .bear_container_with_image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bear_circle_img_container {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-right: 24px;
  margin-bottom: 8px;
}

.bear_rect_img_container {
  width: 80px;
  height: 80px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-right: 24px;
  margin-bottom: 8px;
}

.banner_item_text {
  margin-left: 0px;
  width: 100% !;
}

.bear_edit_section {
  margin: 18px 0px;
  width: 100%;
}

.banner_label {
  padding-bottom: 6px;
  text-transform: capitalize;
}

.banner_input_sec input {
  background-color: hsla(0, 0%, 76.9%, 0.2);
  border-radius: 100px;
  border: solid 1px #f2f3f7;
  height: 42px;
  padding-left: 14px;
  width: 100%;
  padding-right: 12px;
}

.bear_submit_footer {
  text-align: center;
}

.bear_submit button {
  width: 184px;
}

.button-sets {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-sets .button-left,
.button-sets .button-right {
  margin: 0px 12px;
}

.show-message {
  padding-top: 12px;
  text-align: center;
}
