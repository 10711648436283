/**
 * @license
 * Copyright (C) Woofy, Inc. - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium, is strictly prohibited
 *
 * Proprietary and confidential
 * Author: Arjun Rai, arjun@hellowoofy.com, April 2019
 */

@import './fonts/fonts.css';

body {
	margin: 0;
	padding: 0;
	font-family: ProximaNova, sans-serif;
	background-color: #fbfbfb;
	-webkit-font-smoothing: antialiased;
}

body.with-ext-modal {
	background: rgba(255, 255, 255, 0.6);
}

#root {
	width: 100%;
}

h1 {
	font-family: ProximaNovaT, sans-serif;
	font-size: 36px;
	line-height: 44px;
	font-weight: bold;
	color: #747b95;
	margin-bottom: 0;
}

#rectangle {
	border: 10px solid transparent;
	border-left: 12px solid #757a94;
	display: inline-block;
	position: relative;
}

/* Modal */
.modal {
	background-color: rgba(116, 123, 149, 0.3);
}

.modal-dialog.modal-lg {
	max-width: 940px;
}

.modal .modal-content {
	border: none;
}

.modal .modal-title {
	color: #747b95;
	font-size: 24px;
}

.modal .modal-header .close {
	color: #747b95;
	font-size: 32px;
	font-family: ProximaNovaT, sans-serif;
	position: absolute;
	top: 9px;
	right: 17px;
}

.modal .modal-header .close:focus {
	outline-width: 0;
}

.modal .modal-body {
	padding: 1rem 1.25rem;
}

.modal .modal-footer {
	border-top: 0;
	padding: 1rem 1.25rem;
}

@-moz-document url-prefix() {
	.modal .modal-content {
		display: block;
	}
}

/* Form */
label {
	font-size: 14px;
	font-weight: bold;
	color: rgba(116, 123, 149, 0.5);
}

textarea.form-control {
	border-radius: 4px;
	background-color: #f2f3f7;
	border: none;
	resize: none;
	padding: 15px 20px;
}

.form-group {
	margin-bottom: 6px;
}

.custom-control-label:before {
	border: 0.1rem solid #747b95;
	background: #fff;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
	background: #18d1f9;
	border-radius: 5px;
	border: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.custom-checkbox {
	margin-bottom: .5rem;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
	background-color: #fff;
	border: 2px solid #18d1f9;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
	background: #18d1f9;
	border-radius: 50%;
	width: 6px;
	height: 6px;
	transform: translate(75%, 75%);
}

.custom-radio .custom-control-label::before {
	border: 2px solid #747b95;
	background: #fff;
}

.custom-radio {
	margin-bottom: 20px;
}

.custom-select {
	box-shadow: 0 5px 10px 0 rgba(69, 69, 70, 0.1);
	border-radius: 100px;
	background-color: #ffffff;
	color: #747b95;
	border: none;
}

input[type=text] {
	border-radius: 100px;
	background-color: #ffffff;
	border: solid 1px #f2f3f7;
}

.btn-secondary {
	border-radius: 100px;
	background-color: #705eff;
	font-size: 14px;
	font-weight: bold;
	border-color: #705eff;
	box-shadow: 0 5px 10px 0 rgba(116, 123, 149, 0.21);
}

.btn-secondary:hover {
	background-color: #5944ff;
	border-color: #5944ff;
}

.btn-secondary:not(:disabled):not(.disabled):active {
	background-color: #705eff;
	border-color: #705eff;
}

.btn-secondary:focus {
	box-shadow: 0 0 0 0;
}

.btn-primary {
	background-color: #18d1f9;
	border-radius: 100px;
	font-weight: bold;
	font-size: 14px;
	border-color: #18d1f9;
	box-shadow: 0 2px 15px 0 rgba(49, 218, 255, 0.41)
}

.btn-primary:hover {
	background-color: #10c8ec;
	border-color: #10c8ec;
}

.btn-primary:not(:disabled):not(.disabled):active {
	background-color: #10c8ec;
	border-color: #10c8ec;
}

.btn-primary:focus {
	box-shadow: 0 0 0 0;
}

.btn-link {
	color:rgba(116, 123, 149, 0.5);
	text-decoration: none;
	border-radius: 100px;
}

.btn-link:focus {
	text-decoration: none;
	color:rgba(116, 123, 149, 0.5);
}

.btn-link:hover {
	text-decoration: none;
	color:rgba(116, 123, 149, 0.5);
}

.btn-light {
	border-radius: 100px;
	background-color: #ffffff;
	box-shadow: 0 5px 10px 0 rgba(69, 69, 70, 0.05);
	font-size: 14px;
	font-weight: bold;
	color: #747b95;
}

.btn-info {
	background-color: #f9fafc;
	border-radius: 40px;
}

.btn-outline-info:not(:disabled):not(.disabled):active {
	background-color: #f9fafc;
	border-color: #18d1f9;
}

.btn-light:hover {
	background-color: #fff;
	color: #747b95;
}

.btn-outline-info:hover {
	background-color: #f9fafc;
	border-color: #18d1f9;
}

h4 {
	font-size: 16px;
	color: #747b95;
	line-height: 0;
	margin-bottom: 0;
}

.btn-coming-soon {
	background-color: #f9fafc;
	color: #18d1f9;
	border-radius: 100px;
	font-weight: bold;
	font-size: 14px;
	border: 2px solid #18d1f9;
}

.btn-coming-soon:hover {
	background-color: #18d1f9 ;
	color: #f9fafc;
	border-color: #18d1f9;
}

.modal-open .modal {
	overflow: auto!important;
}

.bs-tooltip-top .arrow:before {
	border-top-color: #07a3ed;
}

.btn-light.disabled, .btn-light:disabled {
	opacity: .3;
	cursor: auto;
}

.btn-light:hover {
	color: inherit;
}

footer {
	width: 100%;
	height: 1.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	color: #747b95;
	background-color: #e2e2e2;
	position: fixed;
	bottom: 0;
	z-index: 9;
}

#select-hour {
	display: flex;
	width: 16rem;
}

#launcher {
	bottom: 1.5rem!important;
	right: 1.25rem!important;
}

.zendesk-position #launcher {
	right: 2.75rem!important;
}

.zendesk-position #ailoader-wrap {
	right: 12.5rem;
}

#post-warning-tooltip {
	margin-left: .75rem;
}

#post-warning-tooltip .warning-tooltip-icon {
	display: flex;
	width: 1.25rem;
	height: 1.25rem;
}

.post-campaign-warning-tooltip {
	width: 18rem;
}

.tool-tip {
	color: #FFFFFF;
	font-size: 14px;
	font-family: ProximaNova, sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: center;

}

.tool-tip .tool-tip-bold-text {
	font-weight: bold;
	text-align: left;
}

.tool-tip .tool-tip-text-link {
	color: #FFFFFF;
	text-decoration: underline;
}

.tool-tip .tool-tip-text {
	text-align: justify;
}
